.c-scrolling-banner {
  position: relative;
  background-image: url("/docroot/loacker/dist/images/pure-goodness-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  

  .c-scrolling-banner-bg-element {
    position: absolute;
    bottom: 30%;
    right: 0%;
  }

  .scrolling-banner-container {
    margin: 128px auto;
    max-width: 1176px;
    padding-right: spacer(3);
    padding-left: spacer(3);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 2.08%;

    .scrolling-banner-heading {
      grid-column-start: 1;
      grid-column-end: 5;
      position: sticky;
      top: spacer(10);
      padding-top: 24px;
      align-self: flex-start;

      img {
        max-width: 100%;
      }

    }

    .list {
      grid-column-start: 6;
      grid-column-end: 13;
      display: grid;
      grid-row-gap: 128px;
    }

    .scrolling-banner-card {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 11.1%;
      margin: 0;

      picture {
        grid-column-start: 1;

        img {
          //grid-column-end: 4;
          border-radius: 4px;
          width: 100%;
          vertical-align: middle;
        }
      }

      a {
        grid-column-start: 2;
        align-self: center;

        &:after {
          @include sprite(chevron-link);
          content: "";
          display: block;
          width: 24px;
          height: 24px;
          margin-top: 16px;

          [dir='rtl'] & {
            transform: scale(-1);
          }
        }
      }

      &:nth-of-type(even) {
        picture {
          grid-column-start: 2;
          grid-row: 1/auto;

          img {
            width: 100%;
          }
        }

        a {
          grid-column-start: 1;
        }
      }
    }
  }

  .icons-chevron-link {
    &:before {
      [dir='rtl'] & {
        margin-right: 0;
        margin-left: spacer(1);
        transform: scale(-1);
      }
    }
  }
}