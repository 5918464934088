.c-banner-launch {
  position: relative;
  width: 100%;
  padding: 80px 0;

  .banner-launch-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    grid-auto-flow: dense;
    max-width: 1176px;
    padding-right: spacer(3);
    padding-left: spacer(3);
    margin: 0 auto;

    img {
      width: 100%;
      align-self: center;
      padding: 0 43px
    }

    .text {
      p {
        color: text-color(secondary);
        margin-bottom: 32px;
      }
    }

    &.reverse {
      img {
        grid-column: 2;
      }
    }
  }
}