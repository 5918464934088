.t-loacker-error {
  background-color: bg-color(secondary);

  .error {
    text-align: center;
    background-color: bg-color(secondary);

    img {
      max-width: 100%;
    }

    h1 {
      color: text-color(brand);
      font-size: rem(14);
      font-weight: bold;
      line-height: 16px;
    }

    p {
      color: text-color(primary);
      font-size: rem(28);
      font-weight: bold;
      letter-spacing: 0;
      line-height: 32px;
    }

    .button-primary {
      margin-top: spacer(5);
    }
  }
}