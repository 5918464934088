.section-c-paragraph {
  position: relative;
}

.c-paragraph {
  position: relative;
  max-width: 1128px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 24px;
  margin: spacer(3) auto;

  .paragraph-container {
    grid-column: 3/ span 8;
    margin: 0 auto;

    div {
      position: relative;
      width: calc(50% - 12px);
      min-height: 232px; // TODO: set up width and height in jsp
      margin-left: calc(-25% - 6px);
      margin-right: 24px;
      float: left;

      [dir='rtl'] & {
        margin-right: calc(-25% - 6px);
        margin-left: spacer(3);
        float: right;
      }

      picture {
        display: block;
        width: calc((100% - 72px) / 4 * 3 + 48px);

        &:after {
          content: "";
          position: absolute;
          width: 4px;
          height: 232px;
          top: 0;
          left: calc(72px + ((100% - 72px) / 4) * 3.2222);
          background-color: bg-color(brand);

          [dir='rtl'] & {
            right: calc(72px + ((100% - 72px) / 4) * 3.2222);
            left: auto;
          }
        }

        img {
          width: 100%;
        }
      }

      a {
        font-family: font-family(primary);
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        margin-left: spacer(3);
        width: calc((100% - 72px) / 4 * 3 + 24px);

        [dir='rtl'] & {
          margin-right: spacer(3);
          margin-left: 0;
        }

        &.icons.icons-chevron-link:before {
          min-width: 24px;
          min-height: 24px;
        }
      }
    }

    .with-line {
      position: relative;
      padding-top: spacer(5);

      &:before {
        content: "";
        width: 72px;
        height: 2px;
        background-color: bg-color(brand);
        position: absolute;
        top: 0;
        left: 0;

        [dir='rtl'] & {
          right: 0;
          left: auto;
        }
      }
    }

    a {
      color: text-color(brand);
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      margin-bottom: 0;
    }

    ul, ol {
      font-size: rem(18);
      color: text-color(secondary);
    }
  }

  & + .c-paragraph {
    margin-top: 0;

    .paragraph-title {
      margin-top: 0;
    }
  }
}