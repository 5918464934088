.c-accordion {
  padding: spacer(7) 0 0 0;

  + .c-accordion {
    padding-top: 0; // helps when you have n component accordion in a row
  }
}

.accordion-collapse {
  &.accordion-trigger {
    &.trigger-active {
      color: text-color(brand);

      &:after {
        transform-origin: center center;
        transform: rotate(180deg);
        //-webkit-transform: rotate(180deg);
        //-ms-transform: rotate(180deg);
      }
    }
  }
}

.c-accordion-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 24px;
  max-width: 1128px;
  margin: 0 auto;

  .accordion-trigger {
    position: relative;
    grid-column: 3/span 8;
    grid-row: 1;
    //text-align: left;
    min-height: 80px;
    padding: 0;
    cursor: pointer;
    border-top: 1px solid border-color(breakLight);
    font-family: font-family(primary);
    font-size: rem(16);
    font-weight: bold;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      flex-grow: 1;
      display: flex;
      text-align: left;
    }

    &:hover {
      color: text-color(brand);
    }

    &:after {
      @include sprite(accordion);
      content: "";
      position: relative;
      color: text-color(brand);
      min-width: 24px;
      width: 24px;
      height: 24px;
      display: block;
      //float: right;
      //right: 10px;
      transition-duration: .5s;
      transition-property: transform;
      right: 0;
      background-position: right;
      margin-right: 10px;
    }
  }

  collapse-component.collapsed {
    grid-column: 3/span 7;
    grid-row: 2;

    .accordion-body {
      //grid-column: 3/span 8;
      //grid-row: 2;
      //margin: 0 auto;

      .header6, p {
        font-size: rem(16);
        line-height: rem(24);

      }

      .header6 {
        font-weight: bold;
      }

      & > p {
        margin-bottom: spacer(4);
      }

      .text-image-accordion {
        margin: 0;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        column-gap: 24px;

        picture {
          grid-column: 1/3;

          img {
            width: 100%;
            border-radius: 4px;
          }
        }

        .description {
          grid-column: 3/8;

          > *:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.c-accordion-form-container {

  max-width: 1128px;
  margin: 0 auto;


  .accordion-trigger {
    background-color: #D70D1D;
    position: relative;
    //text-align: left;
    min-height: 80px;
    padding: 0;
    cursor: pointer;
    border-top: 1px solid border-color(breakLight);
    font-family: font-family(primary);
    font-size: rem(16);
    font-weight: bold;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      flex-grow: 1;
      display: flex;
      text-align: left;
    }

    button {
      span.form-accordion-title {
        font-family: AktivGrotesk, Helvetica, Arial, sans-serif;
        font-size: 22px;
        font-weight: 700;
        color: white;
      }
    }

    &:hover {
      color: text-color(brand);
    }

    &.trigger-active {
      background-color: #fff;
      color: text-color(brand);
      border: 1px solid bg-color(brand); ;
      transition: all .3s ease-in-out;
      .form-accordion-title {
        color: text-color(brand);
      }
    }
  }

  collapse-component.collapsed {
    grid-column: 3/span 7;
    grid-row: 2;

    .accordion-body {
      //grid-column: 3/span 8;
      //grid-row: 2;
      //margin: 0 auto;

      .header6, p {
        font-size: rem(16);
        line-height: rem(24);

      }

      .header6 {
        font-weight: bold;
      }

      & > p {
        margin-bottom: spacer(4);
      }

      .text-image-accordion {
        margin: 0;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        column-gap: 24px;

        picture {
          grid-column: 1/3;

          img {
            width: 100%;
            border-radius: 4px;
          }
        }

        .description {
          grid-column: 3/8;

          > *:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}