.modal-container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: none;
  padding: 24px;
  max-width: 100%;

  .header3 {
    small {
      font-size: rem(12);
    }
  }

  p {
    font-size: rem(14);

    &:before {
      width: 72px;
    }
  }
}

.modal-img-container {
  display: flex;
  justify-content: center;

  img {
    max-width: 152px;
    align-self: flex-start;
  }
}