.c-download-list {
  padding: spacer(5) 0;

  .download-list-container {
    margin: 0 23px;

    h2 {
      font-weight: 500;
    }
  }
}