 /* ===WIDTH MEDIA QUERY=== */

/**
 * W3C Custom Media Queries Level 4 allow using the same media query in multiple places, 
 * without the need to duplicate values across a stylesheet, and promote DRYer code.
 * Please, use the @custom-media rule to define a new custom media query.
 * Ref: https://drafts.csswg.org/mediaqueries-5/#custom-mq
 */
@custom-media --xs-only (max-width: #{$screen-xs});
@custom-media --xs-min (min-width: #{$screen-xs});

@custom-media --between-sm-md (min-width: #{$screen-sm}) and (max-width: #{$screen-md});

@custom-media --sm-only (min-width: #{$screen-xs}) and (max-width: #{$screen-sm});
@custom-media --sm-max (max-width: #{$screen-sm});
@custom-media --sm-min (min-width: #{$screen-sm + 1});

@custom-media --md-only (min-width: #{$screen-sm}) and (max-width: #{$screen-md}); ;
@custom-media --md-max (max-width: #{$screen-md});
@custom-media --md-min (min-width: #{$screen-md});

@custom-media --lg-only (min-width: #{$screen-md}) and (max-width: #{$screen-lg}); ;
@custom-media --lg-max (max-width: #{$screen-lg});
@custom-media --lg-min (min-width: #{$screen-lg});

@custom-media --xl-only (min-width: #{$screen-lg}) and (max-width: #{$screen-xl}); ;
@custom-media --xl-max (max-width: #{$screen-xl});
@custom-media --xl-min (min-width: #{$screen-xl});

/**
 * From now on, you will be switching to specific breakpoints ranges.
 * Please refers to abstracts/_grids.scss for the complete list of the breakpoints used.
 * If you need any other media queries, please add them here below.
*/

/* ===IMPORT *FROM-EXTRASMALL* MODULES STYLE=== */

@media (--xs-min) {
  @import 'modules/01_xs-min/modules';
}

/* ===IMPORT *FROM-SMALL* MODULES STYLE=== */
@media (--sm-min) {
  @import 'modules/02_sm-min/modules';
}

/* ===IMPORT *SMALL-ONLY* MODULES STYLE=== */
@media (--sm-only) {
  @import 'modules/02_sm-only/modules';
}

/* ===IMPORT *FROM-MEDIUM* MODULES STYLE=== */
@media (--md-min) {
  @import 'modules/03_md-min/modules';
}

/* ===IMPORT *MEDIUM-ONLY* MODULES STYLE=== */
@media (--md-only) {
  @import 'modules/03_md-only/modules';
}

/* ===IMPORT *FROM-LARGE* MODULES STYLE=== */
@media (--lg-min) {
  @import 'modules/04_lg-min/modules';
}

/* ===IMPORT *LARGE-ONLY* MODULES STYLE=== */
@media (--lg-only) {
  @import 'modules/04_lg-only/modules';
}

/* ===IMPORT *UNTIL-LARGE* MODULES STYLE=== */
@media (--lg-max) {
  @import 'modules/04_lg-max/modules';
}


/* ===IMPORT *FROM-EXTRA-LARGE* MODULES STYLE=== */
@media (--xl-min) {
  @import 'modules/05_xl-min/modules';
}

/* ===IMPORT *EXTRA-LARGE-ONLY* MODULES STYLE=== */
@media (--xl-only) {
  @import 'modules/05_xl-only/modules';
}

/* ===IMPORT *UNTIL-EXTRA-LARGE* MODULES STYLE=== */
@media (--lg-max) {
  @import 'modules/05_xl-max/modules';
}

/* ===IMPORT *UNTIL-MEDIUM* MODULES STYLE=== */
@media (--md-max) {
  @import 'modules/03_md-max/modules';
}

/* ===IMPORT *UNTIL-SMALL* MODULES STYLE=== */
@media (--sm-max) {
  @import 'modules/02_sm-max/_b_typography';
  @import 'modules/02_sm-max/modules';
}

/* ===IMPORT *ONLY-EXTRASMALL* MODULES STYLE=== */
@media (--xs-only) {
  @import 'modules/01_xs-only/modules';
}

/* ===OTHER MEDIA FEATURES=== */

/**
 * Progressively Enhanced CSS Future Media Queries
 * Detecting devices features through level 4 media features
 * TODO: move elsewhere, this is not the right place for feature queries
 */

@media (pointer: coarse) {
  .which-pointer::after {
    content: 'You have a coarse pointer, are you on a touchscreen device?';
  }
}

@media (pointer: fine) {
  .which-pointer::after {
    content: 'You have a fine pointer, are you using a mouse or trackpad?';
  }
}

@media (hover) {
  .can-i-hover::after {
    content: 'You look like you can hover.';
  }
}

@media (hover: none) {
  .can-i-hover::after {
    content: "I don't think you can hover.";
  }
}
