$font0: Arial, sans-serif; /* Replace with the actual fallback font */
$font1: 'AktivGrotesk'; /* Replace with the actual primary font */
$font2: $font1; /* Replace with the actual primary font. Remove if not required */
$base-fonts-path: '../fonts';

/**
 * Include all the font properties in the following map
 * They will be used in the font-face function, 
 * automatically generated by the @mixin font-face-generator
 */

$fonts: (
    fallback: (
        family: #{$font0}
    ),
    primary: (
        family: #{$font1},
        weights: (
            'Regular': 400,
            'Medium': 500,
            'Bold': 700
        )
    ),
);

@include font-face-generator($fonts, $base-fonts-path);

/**
 * ACCESSIBLE FONT-SIZE-SETUP:
 * All font-sizes should scale accordingly if the user changes the default size settings
 * (medium size - corresponding to 16px in all modern browsers).
 * In order to make the font-size accessible and scalable according to the root element size, 
 * we set the root HTML font-size as a percentage and we use rem unit then.
 * A typical method is to set the HTML font-size to 62.5%. That’s because 62.5% of 16px (typical default browser font-size) is 10px.
 * That would still make 1.6rem = 16px.
 * !NOTE: You only need to change the value of $font-size-rem-base, replacing it with the base font size required for your project if different from 14px, as supposed here
 * Ref: https://snook.ca/archives/html_and_css/font-size-with-rem
 */
$font-size-root: 0.625;
$font-size-percentage-root: 62.5%;
$font-size-browser-base: 16;
$rem-base: $font-size-root * $font-size-browser-base; /* used by rem() function to convert px to rem, according to browser setting */
$font-size-rem-base: 16;

/* ===BASELINE=== */
/**
 * List of modular scale names and values 
 * Ref: http://www.modularscale.com/
 */

$modular-scale: (
    square : 1, // Ratio = 1:1
    minor-second : 1.067, // Ratio = 15:16
    major-second : 1.125, // Ratio = 8:9
    loacker : 1.143, // Loacker Custom Ratio  = 7:8
    minor-third : 1.2, // Ratio = 5:6
    major-third : 1.25, // Ratio = 4:5
    perfect-fourth : 1.333, // Ratio = 3:4
    augmented-fourth: 1.414, // Ratio = 1:√2
    perfect-fifth : 1.5, // Ratio = 2:3
    minor-sixth : 1.6, // Ratio = 5:8
    golden : 1.618, // Ratio = 1:1.618
    major-sixth : 1.667, // Ratio = 3:5
    minor-seventh : 1.778, // Ratio = 9:16
    major-seventh : 1.875, // Ratio = 8:15
    octave : 2 // Ratio = 1:2
);

$baseline: map($modular-scale, golden);
/* Leading is the measurement of space from baseline to baseline in a body of text */
$leading: rem($baseline * $font-size-rem-base);
$leading-base: rem($baseline * $font-size-rem-base - 1);


/* ===TYPOGRAPHY MAP=== */

$typography: (
    body: (
        font-family: #{font-family(primary), font-family(fallback)}
    ),
    h1: (
        font-size: rem(56),
        font-family: #{font-family(primary), font-family(fallback)},
        font-weight: 700,
        line-height: map($modular-scale, square),
        letter-spacing: rem(-1.5),
        color: text-color(primary),
        margin: rem(32 0),
    ),
    h2: (
        font-size: rem(56),
        font-family: #{font-family(primary), font-family(fallback)},
        font-weight: 500,
        line-height: map($modular-scale, loacker),
        color: text-color(primary),
        margin: rem(26 0)
    ),
    h3: (
        font-size: rem(28),
        font-family: #{font-family(primary), font-family(fallback)},
        font-weight: 700,
        line-height: map($modular-scale, loacker),
        color: text-color(primary),
        margin: rem(20 0)
    ),
    h4: (
        font-size: rem(24),
        font-family: #{font-family(primary), font-family(fallback)},
        font-weight: 700,
        line-height: map($modular-scale, perfect-fourth),
        color: text-color(primary),
        margin: rem(24 0)
    ),
    h5: (
        font-size: rem(20),
        font-family: #{font-family(primary), font-family(fallback)},
        font-weight: 500,
        line-height: map($modular-scale, minor-sixth),
        color: text-color(primary),
        margin: rem(16 0)
    ),
    h6: (
        font-size: rem(20),
        font-family: #{font-family(primary), font-family(fallback)},
        font-weight: 400,
        line-height: map($modular-scale, minor-sixth),
        color: text-color(secondary),
        margin: rem(8 0)
    ),
    p: (
        font-size: rem(18),
        font-family: #{font-family(primary), font-family(fallback)},
        line-height: map($modular-scale, minor-seventh),
        color: text-color(secondary),
        margin: rem(12 0)
    ),
    a: (
        font-family: #{font-family(primary), font-family(fallback)},
        color: text-color(primary)
    ),
    small: (
        color: text-color(primary),
        font-family: #{font-family(primary), font-family(fallback)},
        font-size: rem(12),
        line-height: map($modular-scale, minor-second)
    ),
    strong: (
        color: text-color(brand)
    ),
    figcaption: (
        color: text-color(primary),
        font-family: #{font-family(primary), font-family(fallback)},
        font-size: rem(14),
        line-height: map($modular-scale, loacker)
    )
)
 