.c-hero-editorial {

  &.wide-img {
    background-image: none;

    .hero-editorial-container {
      max-width: 1178px;
      background-image: none;
      padding-top: 0;

      [dir='rtl'] & {
        padding-right: spacer(5);
        padding-left: spacer(2);
      }

      .hero-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-column-end: 5;
      }

      .img-container {
        grid-column-start: 5;
      }
    }
  }

  &.full-width-img {

    .hero-editorial-container {
      background: transparent;
      margin-left: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      // padding-bottom: 41.58%;
      min-height: 41.58vw;
      max-width: none;
      padding: 0;

      [dir='rtl'] & {
        margin-right: 0;
        margin-left: auto;
      }

      .breadcrumb {
        display: inline-block;
        background: white;
        margin: 0;
        z-index: 1;
        grid-column-start: 1;
        grid-column-end: 7;
        padding-top: spacer(5);
        padding-right: spacer(2);
        padding-left: spacer(5);
      }

      .text-container {
        z-index: 1;
        width: 100%;
        max-width: 1208px;
        margin: 0 auto;
      }

      .content-block {
        background: bg-color(reverse);
        max-width: 521px;
      }

      .hero-text {
        display: inline-block;
        grid-column-end: 7;
        background: white;
        z-index: 1;
        padding-right: spacer(2);
        padding-left: spacer(5);

        [dir='rtl'] & {
          padding-right: spacer(5);
          padding-left: spacer(2);
        }

        .hero-title {
          margin: 0;
          padding: spacer(4) 0 spacer(5);
        }
      }

      .img-pic {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        .img-container {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}