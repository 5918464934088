.c-double-col {
  max-width: 1176px;
  padding-right: spacer(3);
  padding-left: spacer(3);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 24px;
  margin: spacer(10) auto;

  .double-col-main {
    grid-column: 1/8;
  }

  .aside-info {
    grid-column: 9/13;
    position: relative;

    &:before {
      content: "";
      width: 2px;
      height: 100%;
      background-color: bg-color(brand);
      position: absolute;
      left: spacer(-3);

      [dir='rtl'] & {
        right: spacer(-3);
        left: auto;
      }
    }

    .time {
      margin-bottom: spacer(5);

      &:before {
        @include sprite(clock, (color:text-color(brand)));
        content: "";
        display: block;
        height: 24px;
        width: 24px;
      }
    }

    .lv {
      &:before {
        @include sprite(star, (color:text-color(brand)));
        content: "";
        display: inline-block;
        height: 24px;
        width: 24px;
        margin-right: spacer(1);
      }

      &.max {
        &:before {
          @include sprite(star, (color:text-color(placeholder)));
        }
      }
    }

    .difficulty {
      margin-bottom: spacer(5);
    }

    .difficulty-label {
      margin: 0;
    }

    .recipe-related-products-title {
      margin: 0 0 spacer(3);
      text-align: left;

      [dir='rtl'] & {
        text-align: right;
      }

      &.no-margin-title {
        margin: 0;
      }
    }

    .aside-info-list {
      margin-bottom: spacer(5);

      .aside-info-item {
        font-family: font-family(primary);
        color: text-color(secondary);
        font-size: rem(16);

        img {
          width: 100%;
          max-width: 96px;
        }

        .aside-info-product-name {
          font-size: rem(14);
          line-height: rem(16);
          font-weight: 500;
          letter-spacing: rem(0.2);
          color: text-color(primary);
          margin: 0;
        }
      }
    }

    .document-link {
      color: text-color(brand);
      font-size: rem(18);
      font-weight: 500;
      letter-spacing: 0;
      line-height: rem(32);
      text-transform: none;

      &:before {
        transform: rotate(90deg);

        [dir='rtl'] & {
          transform: rotate(-90deg);
        }
      }
    }

    .accordion-trigger {
      button {
        padding: 0;
      }
    }
  }
}