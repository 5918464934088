footer {
  font-family: font-family(primary);
  text-align: center;
  color: text-color(reverse);
  background-color: bg-color(dark);
  padding-top: spacer(3);
  position: relative;
  z-index: 1;

  .header5 {
    color: text-color(reverse);
    margin: spacer(1) 0;
  }

  .footer-address {
    font-size: rem(12);
  }

  ul {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;

    &.social-contacts-links {
      display: flex;
      align-items: center;
      height: 72px;
    }

    li {
      display: inline-block;
      margin: 0 spacer(1.5);

      a {
        color: text-color(reverse);
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: spacer(4);

    p {
      max-width: 768px;
      color: text-color(reverse);
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      margin: spacer(1) spacer(3);
    }

    .webapp-info {
      display: none;
    }
  }

  .footer-link-list {
    text-transform: uppercase;
    font-weight: bold;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1128px;
    margin: 0 auto;

    li {
      font-size: rem(14);
    }
  }

  .bottom-area {
    background-image: linear-gradient(bg-color(dark), bg-color(brand) 1px);
    position: relative;
    padding-bottom: spacer(3);
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    &:before {
      content: "";
      position: relative;
      display: block;
      z-index: -1;
      margin-bottom: spacer(4);
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: bg-color(dark) transparent transparent transparent;
      border-right-width: 50vw;
      border-left-width: 50vw;
      border-top-width: 4vw;
    }

    ul {
      margin-top: spacer(7);

      li {
        position: relative;
        padding: 0 12px;
        margin: 0;

        &:not(:last-child) {
          &:after {
            content: "";
            width: 4px;
            height: 4px;
            background: bg-color(reverse);
            position: absolute;
            right: -4px;
            top: 50%;
            border-radius: 100%;
            transform: translateX(-50%);

            [dir="rtl"] & {
              right: auto;
              left: -4px;
            }
          }
        }
      }
    }

    .brand-expression {
      width: 141px;
      height: auto;
    }
  }

  .visually-hidden {
    color: text-color(reverse);
  }
}

.birthday-footer {
  @extend footer;
  background-image: url("/docroot/loacker/dist/images/footer_no_cake.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  background-color: bg-color(reverse);
  color: text-color(reverse);

  .bithday-footer-wrapper {
    margin-top: 7rem;
    footer .bottom-area:before,
    .bottom-area:before {
      border: none;
    }

    footer .bottom-area,
    .bottom-area {
      background-image: none;
    }

    footer .footer-link-list,
    .footer-link-list {
      font-weight: 500;
    }

    .footer-p {
      font-weight: 400;
      font-size: 14px;
      line-height: 31px;
      color: text-color(reverse);
    }
    .birthday-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .birthday-cake-wrapper {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .social-contacts-links-wrapper {
      margin-left: auto;
      margin-right: 8rem;
    }

    .footer-link-list {
      margin-top: 10rem;
    }
  }
}
