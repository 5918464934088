/* ===GRID SETUP=== */
/**
 * l_pillars is the main grid file, you don't need to change anything here,
 * unless you need to add customized grid rules for specific breakpoints.
 * Otherwise, you can only customize the grid variables 
 * that you can find into the _var_grid.scss abstract file
 */

body {
  &.modal-in {
    overflow: hidden;
    width: 100%;
  }

  section {
    &:last-of-type {
      margin-bottom: 56px;
    }
    &.bg-secondary {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
