slider-componentcst {
  grid-template-columns: 1fr;

  .scroll-icon {
    display: none;
  }

  .container {
    height: calc(50vh - 16px);

    ul {

      li {


        &:first-child {

        }
      }
    }

    .slide {

    }

    .c-hotspot {
      min-height: auto;

      .image-block {

      }
    }

    button {

      &.next {

      }
    }
  }

  .side-slider {
    grid-row: 2;
    min-height: calc(50vh - 32px);
    padding-left: 0;
    display: flex;
    align-items: center;

    [dir='rtl'] & {
      padding-right: 0;
    }

    .hotspot-heading {
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 spacer(3);

      [dir='rtl'] & {
        padding: 0 spacer(3);
      }

      .editmode & {

      }

      &:not(:first-child) {

      }

      .header2 {
        margin: spacer(3) 0 spacer(2);
      }

      p {
        &:not(.header6) {
          font-size: rem(16);
          font-weight: 500;
          letter-spacing: 0;
          line-height: rem(24);
          margin-bottom: spacer(4);
        }
      }
    }

    .button-primary {
      width: 100%;
      display: block;
      text-align: center;
      margin-bottom: spacer(5);
    }
  }

  .editmode & {
    .mgnlEditorBar {

    }

    .c-hotspot {
      .image-block {

      }
    }
  }

  .navigation {
    left: spacer(3);
    top: calc(50vh - 28px);
    transform: translateY(-50%);
    height: 40px;

    [dir='rtl'] & {
      right: spacer(3);
      left: auto;
    }
  }
}