.c-video {
  video {
    object-fit: cover;
    height: 664px;
    max-height: calc(100vh - 48px);
    min-height: 500px;
  }

  video-component {
    .video-container {
      min-width: 100%;
      bottom: 32px;
      padding: 0 spacer(3);

      .buttons {
        .button-secondary {
          display: block;
          margin-right: 0;
          margin-bottom: spacer(3);
          text-align: center;
        }
        button {
          height: 48px;
          width: 100%;
          font-size: rem(14);
        }
      }
    }

    .player-button {
      left: spacer(3);
    }
  }
}