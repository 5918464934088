.birthday-img-container {
  .birthday-cake {
    position: fixed;
    top: 15%;
    left: 10%;
    z-index: 10;
    cursor: pointer;
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 20;
    top: 12%;
    left: 0;
    width: 100%;  
    height: 30vh;
  }
  
  .modal-content {
    color: text-color(reverse);
    padding: 0;
    text-align: center;
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }
  
  .birthday-modal-container {
    display: flex;
    position: relative;
    background-image: url("/docroot/loacker/dist/images/mobile/modal-mobile.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%; 
    height: 100%;
    color: text-color(reverse);
    padding: 0;
    box-sizing: unset;
    border-radius: 10px;
    
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  /* Text Section */
  .birthday-modal-text {
    display: flex;
    flex: 1.5;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: bg-color(reverse);
    border-radius: 10px;
    padding: 8px 16px;
    position: absolute;
    left: 35%;
    right: 5%;
    margin-left: auto;
    top: 15%;
  
    img {
      height: 60px;
    }
  
    p {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 2%;
      color: text-color(secondary);
      margin-top: 0;
    }
  
    .discover-more {
      font-size: 11px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      text-transform: uppercase;
  
      .discover-more-image {
        width: 19px;
        height: 20px;
        vertical-align: text-bottom;
        margin-right: 8px;
      }
    }
  }
  
  .close {
    cursor: pointer;
    color: text-color(primary);
    position: absolute;
    top: 15%;
    left: 85%;
    font-size: 28px;
  
    &:hover,
    &:focus {
      color: text-color(primary);
    }
  }
}
