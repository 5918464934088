.c-plp {
  .c-plp-container, .c-plp-container.reverse {
    min-height: auto;

    .plp-heading {
      grid-column: span 12;
      grid-row: 1;
      padding: 0 spacer(3);
    }

    .products-list {
      grid-column: span 12;
      grid-row: 3;
      margin-top: -18%;
      z-index: 1;

      .product-element {
        grid-column: auto/span 3;

        .special-attr {
          width: 40px;
          height: 40px;
          position: absolute;
          right: 12px;
          top: 24px;
        }
      }
    }

    .aside-container {
      grid-column: span 12;
      grid-row: 2;
      height: 100%;
      width: 100%;

      .aside-img {
        width: 100%;
        height: 224px;
        position: relative;
        background-attachment: scroll;
        background-position: center;
        [dir='rtl'] & {
          background-position: center;
        }
      }
    }
  }
}