/* ===NORMALIZE STYLE=== */
@include normalize();

html {
  box-sizing: border-box;
  scroll-behavior: smooth; /* Supported by firefox and Chrome only. Use a fallback for unsupported browsers, if you need. */
  font-size: $font-size-percentage-root;

  *,
  *:before,
  *:after {
    /**
     * Use inheritance here in order to make easier
     * to change the box-sizing in plugins or other components that leverage other behavior.
     * Credits: Jonathan Neal http://blog.teamtreehouse.com/box-sizing-secret-simple-css-layouts#comment-50223
     */
    box-sizing: inherit;
  }

  *:focus {
    outline: 3px solid cornflowerblue;
  }

  &[data-whatinput='mouse'] *:focus {
    outline: none;
  }

  &[data-whatinput='touch'] *:focus {
    outline: none;
  }

  &.has-v-grid {
    /**
     * !TODO: check $leading and $leading-base value
     */
    @debug '[TYPO SETUP] Leading measures #{$leading}';
    background: linear-gradient(
                    to bottom,
                    rgba(bg-color(reverse), 0) 0%,
                    rgba(bg-color(secondary), 0) $leading-base,
                    rgba(bg-color(secondary), 1) $leading);
    background-size: 100% $leading;
    background-repeat: repeat-y;
  }
}

body {
  font-size: rem($font-size-rem-base);
  line-height: $baseline;
  text-rendering: optimizeLegibility;
  background-color: var(--body-bg);
}

main {
  color: var(--main-color);
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

