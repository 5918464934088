.features {
  margin: 0;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  li {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 20px;
  }

  span {
    height: 100%;
    max-width: 90%;
    display: block;
    border-bottom: 1px solid;
  }
}
