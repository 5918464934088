.birthday-countdown-container {
  background-image: url("/docroot/loacker/dist/images/1.png");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  background-position: center;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  
    .birthday-countdown-wrapper {
  
      countdown-component {
        top: 17rem;
      }
  
      .flipdown .rotor {
        font-size: 7rem;
        font-weight: 700;
        font-family: $font1;
        border-radius: 5.91px;
      }
  
      .flipdown .rotor,
      .flipdown .rotor-leaf,
      .flipdown .rotor-leaf-front,
      .flipdown .rotor-leaf-rear,
      .flipdown .rotor-top,
      .flipdown .rotor-bottom,
      .flipdown .rotor:after {
        width: 51.74px;
      }
  
      .flipdown .rotor-group:nth-child(1):before,
      .flipdown .rotor-group:nth-child(1):after {
        content: "";
        position: absolute;
        left: 120px;
        height: 8px;
        width: 8px;
        background-color: black;
        border-radius: 50%;
      }
  
      .flipdown .rotor-group:nth-child(1):before {
        bottom: 35px;
      }
  
      .flipdown .rotor-group:nth-child(1):after {
        bottom: 55px;
      }
  
      .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):before,
      .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
        left: 120px;
      }
  
      .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):before {
        bottom: 35px;
        height: 8px;
        width: 8px;
      }
  
      .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
        bottom: 55px;
        height: 8px;
        width: 8px;
      }
  
      .flipdown .rotor-leaf-front,
      .flipdown .rotor-top {
        line-height: 103.48px;
      }
  
      .flipdown .rotor-leaf,
      .flipdown .rotor {
        height: 103.48px;
      }
  
      .flipdown .rotor-leaf-front,
      .flipdown .rotor-leaf-rear,
      .flipdown .rotor-top,
      .flipdown .rotor-bottom,
      .flipdown .rotor:after {
        height: 51.74px;
      }
    }
    .birthday-text-wrapper {
  
      .text-container {
        width: 75%;
        
        h1 {
          font-weight: 700;
          font-size: 57px;
          line-height: 70px;
          color: text-color(reverse);
          letter-spacing: -3%;
        }

        span {
          font-weight: 500;
          font-size: 57px;
          line-height: 70px;
          color: text-color(reverse);
        }

        p {
          font-weight: 700;
          font-size: 20px;
          line-height: 31px;
          color: text-color(reverse);
          margin: 2rem 0rem 2rem 0rem;
        }
  
        button {
          margin-top: 4rem;
          width: 50%;

          a {
            color: text-color(primary);
          }
        }
      }
    }
  }
  