/* ===PALETTE SETUP=== */

/**
 * Replace the following colors with the one you actually need for your project.
 * These ones below are used just for sample scope and were taken from http://bada55.io/
 */

$black: #000000;
$white: #ffffff;

$alcool: #a1c001;
$booobs: #b000b5;
$badass: #bada55;
$coffee: #c0ffee;
$decode: #dec0de;
$erotic: #e2071c;
$fables: #fab135;
$loaded: #10aded;
$rebecca: #663399;
$tunno: #5000c4;
$lalala: #1a1a1a;

/** extracted from Loacker Invision **/
//TODO remove unnecessary colors
$whitesmoke:  #F5F5F5;
$beige: #F5F2E9;
$darkBeige: #F0D09A;
$darkerBeige: #EFECE2;
$brown: #A48A7A;
$darkbrown: #A78053;
$extradarkgray: #252525;
$gray: #B3B3B3;
$silvergray: #B0B0B0;
$lightgray: #F1F1F1;
$darkgray: #4C4C4C;
$loackerRed: #D70D1D;
$loackerRedDarker: #A70A16;


/**
 * in order to use one the colors included in the palette map,
 * you can simply use the functions text-color(key), bg-color(key), border-color(key)
 * ie: text-color(primary)
 */

$palette: (
    text: (
        primary: $black,
        secondary: $darkgray,
        secondaryLighter: $lightgray,
        secondaryDarkBrown: $darkbrown,
        placeholder: $gray,
        tertiary: $beige,
        tertiaryDarker: $darkerBeige,
        tertiaryDark: $darkBeige,
        reverse: $white,
        brand: $loackerRed,
        success: $badass,
        warning: $fables,
        error: $erotic
    ),
    background: (
        primary: $black,
        secondary: $beige,
        secondaryDarker: $brown,
        tertiary: $beige,        
        tertiaryDark: $darkBeige,
        tertiaryDarker: $darkerBeige,
        whitesmoke: $whitesmoke,
        reverse: $white,
        silvergray: $silvergray,
        brand: $loackerRed,
        success: $badass,
        warning: $fables,
        error: $erotic,
        dark: $extradarkgray
    ),
    border: (
        primary: $black,
        secondary: $beige,
        tertiary: $darkgray,
        tertiaryDarker: $darkerBeige,
        break: $gray,
        breakLight: $lightgray,
        reverse: $white,
        brand: $loackerRed,
        success: $badass,
        warning: $fables,
        error: $erotic
    )
);

/* ===BUTTONS COLORS SETUP=== */

/**
 * The $buttons map is used to style buttons
 * Please consider that the key used is the one used for classes name as well
 * ie: .button-primary, .button-secondary
 */

$buttons: (
    primary: (
        color: $white,
        background-color: $loackerRed,
        border-color: $loackerRedDarker,
        hover-color: $white,
        hover-background-color: $loackerRedDarker,
        hover-border-color: $loackerRedDarker,
        active-color: $rebecca,
        active-background-color: $booobs,
        active-border-color: $black
    ),
    secondary: (
        color: $black,
        background-color: $white,
        border-color: $gray,
        hover-color: $black,
        hover-background-color: $lightgray,
        hover-border-color: $white,
        active-color: $white,
        active-background-color: $white,
        active-border-color: $white
    )
);
