.c-triptych {
  //margin: spacer(16) auto;
  position: relative;
  padding-bottom: spacer(5);
  margin: spacer(10) auto 0;

  .triptych-container {
    margin: 0 auto;
    max-width: 1176px;
    padding-right: spacer(3);
    padding-left: spacer(3);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 2.08%;
    grid-row-gap: 24px;
    grid-auto-flow: dense;
    //padding-bottom: spacer(7);

    .triptych-heading {
      grid-column-start: 8;
      grid-column-end: 13;
      top: 0;
      padding: spacer(6) 0;

      &.quote-heading {
        grid-column-start: 7;
        grid-column-end: 12;
      }

      .quote {
        position: relative;
        font-family: font-family(primary);
        line-height: 32px;

        &:before {
          @include sprite(loacker-1925-seal);
          content: "";
          position: relative;
          display: block;
          width: 88px;
          height: 88px;
        }
      }

      .triptych-cta {
        margin-top: spacer(5);

        &:before {
          [dir='rtl'] & {
            margin-right: 0;
            margin-left: spacer(1);
            transform: scale(-1);
          }
        }
      }
    }

    &.reverse {
      .triptych-images {
        grid-column-start: 7;
        grid-column-end: 13;
      }

      .triptych-heading {
        grid-column-start: 1;
        grid-column-end: 6;
      }
    }

    .triptych-images {
      display: grid;
      grid-column-start: 1;
      grid-column-end: 7;
      grid-template-columns: repeat(18, 1fr) 0.6fr repeat(4, 1fr) 0.4fr;
      grid-row-gap: 20px;
      align-self: flex-start;
      margin-top: spacer(-3);

      .triptych-picture {
        margin: 0;
        position: relative;
        height: 0;
        padding-top: 78.8%;

        img {
          border-radius: 4px;
          width: 100%;
          vertical-align: middle;
          position: absolute;
          top: 0;
          bottom: 0;
          height: 100%;
          object-fit: cover;
        }


        &.first {
          grid-row: 1/3;
          grid-column: 1/13;
          align-self: center;
        }

        &.second {
          padding-top: 119.7%;
          grid-column: 14/27;
        }

        &.third {
          grid-column: 14/20;
        }

        &.second, &.third {

        }

        &.first, &.third {
          padding-top: 100%;
        }
      }
    }
  }
}