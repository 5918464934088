slider-componentcst {
  &.full-width {
    grid-template-columns: 100%;
    min-height: calc(90vh - 56px);

    .side-slider {
      // position: absolute;
      z-index: 1;
      padding-left: 0;
      top: 0;
      bottom: 0;
      // background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.0001) 100%);
      // width: 50vw;
      width: 100%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) calc(50% - 564px), transparent);

      [dir='rtl'] & {
        padding-right: 0;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.5) calc(50% - 564px), transparent);
      }

      .header1, p:not(.header6) {
        color: text-color(reverse);
      }

      .hotspot-heading {
        padding-left: spacer(3);
        max-width: 1176px;
        margin: 0 auto;
        & > h2, p {
          max-width: 456px;
        }
      }
    }

    .container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .scroll-icon {
      display: none;
      // padding-left: 0;
      // color: text-color(reverse);
      // z-index: 1;

      &:before {
        // @include sprite(scroll, (color:text-color(reverse)));
      }

      [dir='rtl'] & {
        // padding-right: 0;
        // margin-right: 40px;
      }
    }

    .navigation {
      left: auto;
      transform: none;
      right: 149px;

      [dir='rtl'] & {
        right: auto;
        left: 149px;
      }
    }

    .c-hotspot {
      .image-block {
        .image-trick {
          picture {
            img {
              min-width: 100vw;
            }
          }
        }
      }
    }

    .navigation {
      z-index: 2;
    }
  }
}
