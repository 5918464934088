.c-gallery {

  figure {
    picture {
      padding-top: 75%;
    }

    figcaption {
      padding: 0 spacer(3);
      white-space: normal;

      [dir='rtl'] & {
        padding: 0 spacer(3);
      }
    }
  }

  slider-componentcst {

    .container {
      height: 100%;
    }

    .navigation {
      left: spacer(3);
      top: calc(75% - 32px);

      [dir='rtl'] & {
        right: spacer(3);
        left: auto;
      }
    }
  }
}