.c-accordion {
  .c-accordion-container {
    padding: 0 24px;

    .accordion-trigger {
      grid-column: span 12;
    }

    collapse-component.collapsed {
      grid-column: span 12;
    }
  }
}

