productlisting-component {
  .desktop-only {
    display: none;
  }

  .select-tabs-container {
    top: 48px;
  }

  .reset {
    //display: none;
    box-shadow: 0px 2px 8px 0px rgba(193, 193, 193, 0.3);
    border-radius: 20px;
    padding: 8px;
    margin-top: 16px;
    padding-left: 30px;
    height: 35px;
    width: auto;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    background-color: bg-color(reverse);

    &:before {
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
      width: 22px;
      height: 22px;
    }
  }

  .filters-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: bg-color(reverse);

    .filters-modal-header {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      min-height: 100px;
      position: relative;
      border-bottom: 1px solid border-color(breakLight);

      .close-button-component {
        position: absolute;
        top: spacer(1);
        right: 0;
        filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.3));
      }

      .paragraph {
        font-size: rem(16);
        font-weight: bold;
        color: text-color(primary);
        letter-spacing: 0.2px;
        line-height: rem(16);
        text-align: center;
        padding: spacer(2);
        border-bottom: 2px solid border-color(brand);
      }
    }

    nav-component {
      height: 100%;
      border-bottom: none;
      //margin-bottom: spacer(8);

      .scrolling-nav {
        //height: 100%;
        height: auto;
        overflow-x: hidden;
        overflow-y: scroll;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        li {
          border-bottom: 1px solid border-color(breakLight);
          display: block;

          .filter {
            text-align: left;
            width: 100%;

            [dir='rtl'] & {
              text-align: right;
            }

            &.active {
              &:before {
                content: none;
              }
            }
          }
        }
      }
    }

    .confirmation-modal-component {
      display: block;
      padding: spacer(1) spacer(2.5);

      .confirmation-btn {
        width: 100%;
      }
    }
  }

  .select-tabs {
    text-align: center;
    background: bg-color(secondary);

    &.mobile-only {
      //display: flex;
      //flex-wrap: nowrap;
      display: block;
      white-space: nowrap;
      overflow-x: scroll;
      padding-top: 20px;
      margin-top: -20px;
      //NOTE lines below help with chrome high dpi bug screen on sticky
      left: -1px;
      width: 100vw;
      position: relative;

      [dir='rtl'] & {
        left: auto;
        right: -1px;
      }
    }

    .tab-selector {
      flex-grow: 1;
      justify-content: center;
      margin: 0 spacer(1.5);
    }
  }

  .modal {
    &.collapsed {
      display: none !important;
      //height: 100vh;
      width: 100vw;
      background: #000000b0;
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: 11;

      &.collapse-expanded {
        display: block !important;
      }

      .internal-collapse {
        display: block !important;
        height: 100%;
      }
    }
  }

  .listing {
    margin: spacer(6) 0;

    .banner-product-card-li {
      grid-column-start: 2;
      grid-column-end: 12;
    }

    .banner-product-card {
      min-height: 160px;

      .banner-product-text {
        padding: 0 16px;

        .header5 {
          font-size: rem(14);
          margin: 34px 0;
        }
      }
    }

    .product-card {
      grid-column: span 6;

      .special-attr {
        width: 40px;
        height: 40px;
        right: 24px;
        top: 24px;
      }
    }

    &.article-listing {
      padding: 0 spacer(3);

      .article-card {
        grid-column: span 9;

        .article-card-title {
          font-size: rem(16);
          line-height: rem(24);
          font-weight: 500;
        }

        .article-paragraph {
          line-height: rem(32);
        }
      }
    }
  }
}