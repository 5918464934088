.c-triptych {
  padding: 0 spacer(3) 0;
  margin: spacer(10) 0;

  .triptych-container {

    &.reverse {
      .triptych-images, .triptych-heading {
        grid-column: span 12;
      }
    }

    .triptych-heading {
      position: relative;
      grid-column: span 12;
      padding-bottom: spacer(8);

      &.quote-heading {
        grid-column: span 12;
        grid-row: 2;
        padding: 0;
      }

      .triptych-title {
        margin: 0;
      }
    }

    .triptych-images {
      grid-column: span 12;
      grid-template-columns: repeat(11, 1fr) 24px repeat(6, 1fr) 1fr repeat(4, 1fr);
      grid-template-rows: 0.5fr 0.5fr 20px auto;
      grid-row-gap: 0;
      padding-bottom: spacer(8);


      .triptych-picture {
        &.first {
          grid-column: 1/12;
          grid-row: 2/ 4;
          align-self: auto;
        }

        &.second {
          padding-top: 137.7%;
          grid-column: 13/27;
          grid-row: span 2;
        }

        &.third {
          grid-column: 13/20;
          grid-row: 4;

        }
      }
    }
  }
}



