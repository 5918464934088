.c-banner-pill {
  padding: spacer(5);

  .pill-info {

    .header2 {
      margin: 0;
      font-size: rem(64);

      &:after {
        width: 80px;
        height: 80px;
      }
    }

    .pill-number {
      font-size: clamp(20px, 80px, 16vw);
    }

    .header4 {
      margin: 4px 8% 0;
      font-size: rem(16);
      font-weight: 500;
    }
  }

  .pill-cta {
    //margin-bottom: spacer(3);
  }
}