.c-banner-pill {
  position: relative;
  width: 100%;
  background-color: bg-color(secondary);
  padding: spacer(9) spacer(5);
  text-align: center;

  svg {
    display: block;
    margin: 0 auto;
  }

  .banner-pill-img {
    max-width: 100%;
  }

  .pill-info {
    position: relative;
    margin: spacer(5) 0;
    padding-bottom: spacer(5);

    &:after {
      content: "";
      width: 72px;
      height: 2px;
      background-color: bg-color(brand);
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .pill-number {
      position: relative;
      display: inline-block;
      color: text-color(brand);
      font-weight: bold;
      font-size: rem(80);
      letter-spacing: -2px;
      line-height: 80px;
      margin-top: 0;
      margin-bottom: 0;

      &:after {
        @include sprite(loacker-1925-seal);
        content: "";
        display: inline-block;
        position: absolute;
        top: 6px;
        right: -50px;
        transform: translateY(-50%);
        width: 100px;
        height: 100px;
      }
    }

    .pill-text {
      display: block;
      margin: spacer(1) 0;
    }
  }

  .header4 {
    margin: 4px 17% 0;
    font-weight: bold;
  }

  .pill-cta {
    //margin-top: spacer(8);
    justify-content: center;
  }
}