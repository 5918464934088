.c-related-products {
  text-align: center;
  margin: spacer(16) auto;
  position: relative;
  background: linear-gradient(to bottom, bg-color(secondary) 60%, bg-color(reverse) 40%);
  overflow: hidden;

  .related-products-container {
    max-width: 1128px;
    margin: 0 auto;

    .header2 {
      background-color: bg-color(secondary);
      padding: spacer(10) 0 spacer(7);
      margin-bottom: 0;
    }

    .square-listing {
      display: flex;
      padding: 0;
      margin-top: 0;
      //margin-top: spacer(4);
      justify-content: center;
      flex-wrap: wrap;

      li {
        width: calc(25% - 24px * 3); // un quarto - i margini


        &:first-child:nth-last-child(1) {
          /* -or- li:only-child { */
          picture {
            &:before {
              left: calc((-100vw + (100% * 1 + 24px * 0)) / 2);
              width: calc((100vw - (100% * 1 + 24px * 0)) / 2);

              [dir='rtl'] & {
                right: calc((-100vw + (100% * 1 + 24px * 0)) / 2);
                left: auto;
              }
            }

            &:after {
              right: calc((-100vw + (100% * 1 + 24px * 0)) / 2);
              width: calc((100vw - (100% * 1 + 24px * 0)) / 2);

              [dir='rtl'] & {
                right: auto;
                left: calc((-100vw + (100% * 1 + 24px * 0)) / 2);
              }
            }
          }
        }

        /* two items */
        &:first-child:nth-last-child(2) {
          picture {
            &:before {
              left: calc((-100vw + (100% * 2 + 24px * 1)) / 2);
              width: calc((100vw - (100% * 2 + 24px * 1)) / 2);

              [dir='rtl'] & {
                right: calc((-100vw + (100% * 2 + 24px * 1)) / 2);
                left: auto;
              }
            }
          }

          & ~ li:last-child {
            picture {
              &:after {
                right: calc((-100vw + (100% * 2 + 24px * 1)) / 2);
                width: calc((100vw - (100% * 2 + 24px * 1)) / 2);

                [dir='rtl'] & {
                  right: auto;
                  left: calc((-100vw + (100% * 2 + 24px * 1)) / 2);
                }
              }
            }
          }
        }

        /* three items */
        &:first-child:nth-last-child(3) {
          picture {
            &:before {
              left: calc((-100vw + (100% * 3 + 24px * 2)) / 2);
              width: calc((100vw - (100% * 3 + 24px * 2)) / 2);

              [dir='rtl'] & {
                right: calc((-100vw + (100% * 3 + 24px * 2)) / 2);
                left: auto;
              }
            }
          }

          & ~ li:last-child {
            picture {
              &:after {
                right: calc((-100vw + (100% * 3 + 24px * 2)) / 2);
                width: calc((100vw - (100% * 3 + 24px * 2)) / 2);

                [dir='rtl'] & {
                  right: auto;
                  left: calc((-100vw + (100% * 3 + 24px * 2)) / 2);
                }
              }
            }
          }
        }

        /* four items */
        &:first-child:nth-last-child(4) {
          picture {
            &:before {
              left: calc((-100vw + (100% * 4 + 24px * 3)) / 2);
              width: calc((100vw - (100% * 4 + 24px * 3)) / 2);

              [dir='rtl'] & {
                right: calc((-100vw + (100% * 4 + 24px * 3)) / 2);
                left: auto;
              }
            }
          }

          & ~ li:last-child {
            picture {
              &:after {
                right: calc((-100vw + (100% * 4 + 24px * 3)) / 2);
                width: calc((100vw - (100% * 4 + 24px * 3)) / 2);

                [dir='rtl'] & {
                  right: auto;
                  left: calc((-100vw + (100% * 4 + 24px * 3)) / 2);
                }
              }
            }
          }
        }

        &:not(:last-child) {
          margin-right: spacer(3);

          [dir='rtl'] & {
            margin-right: 0;
            margin-left: spacer(3);
          }

          picture {
            &:after {
              content: "";
              position: absolute;
              top: 0;
              right: -24px;
              width: 24px;
              height: 100%;
              background: linear-gradient(to bottom, bg-color(secondary) 50%, bg-color(reverse) 50%);

              [dir='rtl'] & {
                right: auto;
                left: -24px;
              }
            }
          }
        }


        &:first-child {
          picture {
            &:before {
              content: "";
              height: 100%;
              display: block;
              background: linear-gradient(to bottom, bg-color(secondary) 50%, bg-color(reverse) 50%);
              position: absolute;
              z-index: 0;
              //left: calc((-100vw + (100% * 4 + 24px * 3)) / 2);
              //width: calc((100vw - (100% * 4 + 24px * 3)) / 2);
            }
          }
        }


        &:last-child {
          picture {
            &:after {
              content: "";
              top: 0;
              height: 100%;
              display: block;
              background: linear-gradient(to bottom, bg-color(secondary) 50%, bg-color(reverse) 50%);
              position: absolute;
              z-index: 0;
              //right: calc((-100vw + (100% * 4 + 24px * 3)) / 2);
              //width: calc((100vw - (100% * 4 + 24px * 3)) / 2);
            }
          }
        }


        .square-item {
          position: relative;
          text-align: center;
          margin: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;

          picture {
            position: relative;

            &:before {
              content: "";
              height: 100%;
              display: block;
              background: linear-gradient(to bottom, bg-color(secondary) 50%, bg-color(reverse) 50%);
              position: absolute;
              z-index: 0;
            }

            img {
              vertical-align: middle;
              width: 100%;
              background: linear-gradient(to bottom, bg-color(secondary) 50%, bg-color(reverse) 50%);
            }
          }

          .description {
            text-transform: uppercase;
            font-weight: bold;
            display: flex;
            height: 64px;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            flex-direction: column;
            margin: 0;

            small {
              text-transform: none;
              font-size: rem(15);
              font-weight: 500;
              color: text-color(brand);
              display: block;
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }
}