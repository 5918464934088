productlisting-component {

  &.filters-sticky {
    .select-tabs-container {
      background-color: bg-color(reverse);
      border: 1px solid border-color(breakLight);
    }

    &.secondary-bg {
      .select-tabs-container {
        background-color: bg-color(secondary);
        border: none;
      }
    }
  }

  .sticky-filters-container {
    //position: sticky;
    //top: 40px;
    //z-index: 5;
    display: inline;
  }

  .reset {
    position: absolute;
    margin-top: -25px;
    right: 40px;
    color: text-color(brand);
    font-family: font-family(primary);
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1px;

    &.disable {
      display: none;
    }

    &:before {
      @include sprite(load);
      content: "";
      position: absolute;
      left: -21px;
      top: 50%;
      transform: translateY(-50%);
      width: 22px;
      height: 22px;
    }
  }

  .select-tabs-container {
    background: bg-color(secondary);
    position: sticky;
    top: 40px;
    z-index: 5;
  }

  .select-tabs {
    text-align: center;
    //background: bg-color(secondary);

    &.mobile-only {
      display: none;
    }

    &.desktop-only {

    }

    .tab-selector {
      vertical-align: middle;
      display: inline-flex;
      min-height: 40px;
      margin: 0 spacer(3);
      position: relative;

      .tab-button {
        font-size: rem(16);
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: rem(16);
        text-align: center;
        margin: spacer(0.5);
        padding: spacer(1);
      }

      &.trigger-active {
        border-bottom: 2px solid border-color(brand);

        .tab-button {
          color: text-color(brand);

        }
      }

      .filter-counter {
        //font-family: font-family(primary);
        font-size: rem(11);
        color: text-color(reverse);
        background: bg-color(brand);
        border-radius: 50%;
        height: 16px;
        width: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -20px;
        visibility: hidden;
      }
    }
  }

  .modal {
    &.collapsed {
      display: block !important;
      height: auto;
      width: auto;
      background: none;
      position: sticky;
      z-index: 5;
      top: 80px;
    }
  }

  .filters-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: bg-color(reverse);

    .filters-modal-header {
      display: none;
    }

    .confirmation-modal-component {
      display: none;
    }
  }

  .listing {
    margin: 80px auto;
    max-width: 1128px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 24px;

    &.article-listing {
      max-width: 840px;
      grid-template-columns: repeat(9, 1fr);
      grid-column-gap: 24px;
      min-height: 400px;
    }

    .banner-product-card-li {
      grid-column: span 6;


      /* THIS IS TO DISPLAY RIGHT NUMBER OF PRODUCT IN LISTING (by SimoSami) */
      & ~ li:last-child:not([data-last-element]) {
        display: none;
      }

      & ~ li:nth-last-child(2):not([data-second-last-element]) {
        display: none;
      }
    }

    .banner-product-card {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: bg-color(secondary);
      grid-column: span 6;
      height: 100%;
      max-height: 256px;

      .effect-image {
        position: absolute;
        left: 0;
        right: 0;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      .banner-product-text {
        margin: auto;
        padding: 0 32px;

        .header5 {
          margin-top: 32px;
          padding-bottom: 48px;
          position: relative;

          &:before {
            content: "";
            width: 40px;
            height: 2px;
            background-color: bg-color(brand);
            position: absolute;
            top: -16px;
          }

          &:after {
            @include sprite(chevron-link);
            content: "";
            width: 24px;
            height: 24px;
            display: block;
            position: absolute;
            bottom: 0;
          }
        }

        a {
          &:before {
            position: absolute;
            bottom: 12px;
          }
        }
      }

      .product-card-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 256px;
        grid-column: 2;
      }
    }

    .product-card {
      text-align: center;
      grid-column: span 3;
      position: relative;

      &.article-card {
        text-align: left;

        .img-container {
          position: relative;

          .category-img {
            position: absolute;
            right: spacer(1);
            bottom: spacer(1);
            width: 40px;
            height: 40px;
            object-fit: cover;
          }
        }

        .category-label {
          color: text-color(brand);
          text-transform: uppercase;
          font-size: rem(12);
          font-weight: 700;
          letter-spacing: rem(1);
          line-height: rem(16);
          margin-bottom: 8px;
        }

        .article-card-title {
          line-height: rem(24);
          letter-spacing: 0;
          margin: 8px 0;
        }

        .article-paragraph {
          font-size: rem(16);
          line-height: rem(24);
          margin-top: spacer(1);
        }
      }

      /*
            &:last-child {
              &:before {
                content: "";
                display: block;
                position: absolute;
                height: 100%;
                width: 100vw;
                left: calc(100% - 100vw);
                background: #ffffffc0;
              }
            }
      */

      img {
        width: 100%;
        vertical-align: middle;
      }

      .special-attr {
        width: 56px;
        height: 56px;
        position: absolute;
        right: 32px;
        top: 32px;
      }

      .description {
        font-family: font-family(primary);
        font-weight: bold;
        letter-spacing: 0.3px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        margin: 0;

        small {
          text-transform: none;
          font-size: rem(15);
          font-weight: 500;
          color: text-color(brand);
          display: block;
          margin-bottom: 12px;
        }
      }
    }

    .empty-list {
      grid-column: span 12;
    }
  }

  /*
    [data-load-on-scroll=''] {
      display: none;
    }
  */
  .pagination {
    position: relative;
    margin: spacer(3);
    text-align: center;

    [data-load-more] {
      &.disabled {
        display: none;
      }
    }

    /*
        .load-more {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: text-color(brand);
          font-family: font-family(primary);
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 1px;

          &:before {
            @include sprite(load);
            content: "";
            width: 40px;
            height: 40px;
            position: absolute;
            top: -27px;
            left: 50%;
            transform: translate(-50%, -50%);
            animation: rotateSpin 1s linear infinite;
          }

          &.disabled {
            display: none;
          }
        }
     */
  }
}

@keyframes rotateSpin {
  0% {
    transform: translate(-50%, -50%)
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg)
  }
}