.snack-quest-container {
  padding: 2rem;
  background-image: url("/docroot/loacker/dist/images/mobile/snack-quest-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  // width: 100vw;

  .progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .circle-point {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: bg-color(tertiaryDark);
    text-align: center;
    line-height: 40px;
    margin: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .circle-point:hover {
    background-color: bg-color(brand);
  }

  .circle-point.active {
    background-color: bg-color(brand);
  }

  .snack-quest-heading {
    margin: 7rem;
    display: flex;
    flex-direction: column; 
    align-items: center; 
    margin: 3rem;

    .snack-quest-heading-text {
      flex: none; 
      margin-bottom: 1rem;
      width: 100%; 
      text-align: left;
      white-space: normal; 
      word-wrap: break-word; 

      h2 {
        font-size: 30px;
        font-weight: 500;
        line-height: 34px;
      }

      .highlight {
        color: text-color(brand);
        font-size: 30px;
        font-weight: 500;
        line-height: 34px;
      }

      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 31px;
        margin-bottom: 4rem !important;
      }


      .cursor-text {
        text-align: center;
        width: 50%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    
      .discover-more {
        font-size: 13px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
        text-transform: uppercase;
        white-space: normal;
        word-wrap: break-word;
    
        .discover-more-image {
          width: 24px;
          height: 24px;
          vertical-align: top;
          margin-right: 8px;
        }
      }
    }

    .snack-quest-heading-circle {
      flex: none;
      margin-bottom: 1rem;
      width: 100%;
      text-align: center;

      .progress-container {
        position: relative;
        width: 334px;
        height: 334px;
      }

      .progress-bar {
        transform: rotate(-90deg);
      }

      .progress-line {
        fill: none;
        stroke: bg-color(reverse);
        stroke-width: 5;
      }

      .progress-fill {
        fill: none;
        stroke: bg-color(brand);
        stroke-width: 5;
        stroke-dasharray: 0 1000;
        transition: stroke-dasharray 0.3s ease;
      }

      .point {
        fill: bg-color(tertiaryDark);
        cursor: pointer;
        r: 10;
      }

      .point.active {
        fill: bg-color(reverse);
        stroke: bg-color(brand);
        stroke-width: 5;
        r: 19;
      }

      .point.visited {
        fill: bg-color(brand);
        stroke: bg-color(reverse);
        stroke-width: 2;
      }

      .point:not(.active) {
        stroke: white;
        stroke-width: 2;
        r: 10;
      }

      .point-circle {
        transition: all 0.3s ease;
      }

      .inside-circle {
        fill: bg-color(whitesmoke);
      }

      #inside-circle-content {
        color: black;
        font: 18px serif;
        height: 100%;
        transform: rotate(90deg);

        h3 {
          font-size: 15px;
          font-weight: 700;
          line-height: 19.2px;
          color: text-color(brand);
          margin: 0;
        }

        p {
          font-size: 15px;
          font-weight: 500;
          line-height: 22px;
          color: text-color(secondary);
        }
      }
    }
  }

  .snack-quest-list {
    text-align: center;
    margin: 0;

    h2 {
      font-size: 28px;
      font-weight: 700;
      line-height: 35.84px;
      color: text-color(brand);
    }

    .data-container .loacker-tips {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      pointer-events: auto;
      touch-action: pan-x;
      margin-top: 2rem;
      margin-bottom: 6rem;
    }

    .loacker-tips > * {
      flex: 0 0 70%;
      scroll-snap-align: center;
      margin: 0 10px;
      box-sizing: border-box;
    }
    .item-wrapper {
      text-align: center;
      width: 100%;
      margin-bottom: 10rem;
      margin-left: 2rem;
    }

    .data-item {
      width: 50vw;
      height: 50vw;
      border-radius: 50%;
      border: 3px solid bg-color(reverse);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px;
      margin: 3rem auto;
      position: relative;
      transition: transform 0.3s ease;
    }

    
    .data-item {
      .item-image {
        width: 80%;
        height: 60%;
        object-fit: contain;
        max-width: 80%;
        max-height: 60%;
      }
   }

    .data-item-text {
      position: absolute;
      top: 100%;
      margin-top: 2rem;
    }

    .top-image {
      position: absolute;
      bottom: 90%;
      width: 48px;
      height: 47px;
      height: auto;
    }

    .item-image {
      width: 80%;
      height: 80%;
    }

    .data-item h5 {
      font-size: 15px;
      font-weight: 700;
      line-height: 19.2px;
      color: text-color(brand);
      margin-top: 2rem !important;
      margin-bottom: 0;
    }

    .data-item p {
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      line-height: 28px;
      color: text-color(primary);
      margin: 0;
      text-transform: uppercase;
    }

    .item-details {
      width: 100%;
      margin-top: 10rem;

      .item-description {
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        letter-spacing: 4%;
        margin-bottom: 28px;
        color: text-color(secondary);
      }

      .discover-more {
        font-size: 13px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        text-transform: uppercase;
        .discover-more-image {
          width: 24px;
          height: 24px;
          vertical-align: top;
          margin-right: 8px;
        }
      }
    }

    .tag-button {
      background-color: bg-color(tertiary);
      color: text-color(secondaryDarkBrown);
      padding: 0 1rem;
      font-size: 13px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      border-radius: 6px;
      border: 2px solid bg-color(reverse);
      position: absolute;
      top: 96%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

::-webkit-scrollbar {
  height: 4px;
  pointer-events: none; 

}

::-webkit-scrollbar-track {
  background: bg-color(secondary);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: bg-color(silvergray);
  height: 4px !important;
  border-radius: 10px;
}
