.c-banner-carousel {
  width: 100%;
  background-color: bg-color(secondary);
  padding: spacer(6) 0;

  .banner-carousel-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    max-width: 1176px;
    padding-right: spacer(3);
    padding-left: spacer(3);
    margin: 0 auto;

    .carousel-image {
      width: 100%;
      align-self: center;
    }

    .right-side {
      .svg-image {
        align-self: flex-start;
        margin-bottom: spacer(3);
      }

      display: flex;
      flex-direction: column;
      justify-content: center;

      .header4 {
        margin: 0;
      }

      slider-componentcst {
        height: auto;
        grid-template-columns: auto;

        .side-slider {
          padding-left: 0;

          .hotspot-heading {
            padding: 0;
          }

          p {
            color: text-color(secondary);
          }
        }

        .bullet-div {
          display: flex;
          align-items: center;
          grid-row: 2;
          height: 20px;
          margin-top: spacer(2);

          .bullet {
            width: 10px;
            height: 10px;
            background-color: bg-color(primary);
            border-radius: 50%;
            margin-right: 16px;
            position: relative;
            padding: 0;
            cursor: pointer;

            &.current-slide, &:hover {
              background-color: #ff000057;
              width: 16px;
              height: 16px;
              margin-right: 13px;
              margin-left: -3px;

              &:before {
                content: "";
                width: 10px;
                height: 10px;
                background-color: black;
                display: block;
                position: absolute;
                opacity: 1;
                left: 0;
                right: 0;
                border-radius: 50%;
                margin: 0 auto;
                top: 50%;
                transform: translateY(-50%);
                background: red;
              }
            }
          }
        }
      }
    }
  }
}