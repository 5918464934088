.c-story-item {
  position: relative;
  width: 100%;
  padding: spacer(8) 0;

  &:first-of-type {
    padding: spacer(10) 0;
  }

  sentinel-component {
    position: absolute;
    top: -120px;
    height: 100%;
    width: 100%;
  }

  .story-item-container {
    display: flex;
    align-items: flex-start;
    max-width: 1128px;
    margin: 0 auto;

    &.reverse {
      .year {
        order: 3;
      }
    }

    .year {
      max-width: 33%;
      padding: 0 30px;
      word-break: break-all;
      color: text-color(brand);
      font-size: rem(184);
      line-height: 184px;
      text-align: center;
      position: relative;
      flex-grow: 1;
      .hint-image {
        position: absolute;
        right: 0;
        bottom: -53px;
        max-width: 168px;
      }
    }

    .story-image {
      max-width: 33%;
      margin: 0 spacer(3);
      flex-grow: 1;
    }

    .story-text {
      max-width: 33%;
      flex-grow: 1;
      letter-spacing: 0;

      h4 {
        margin: 0;
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;

        &.section-title {
          color: text-color(brand);
        }
      }
    }
  }
}