.c-text-image {
  padding: 0 spacer(3);
  margin: spacer(4) 0;
  width: auto;

  .text-image-container {
    grid-template-columns: auto;
    grid-row-gap: 24px;

    img, .text {
      grid-column: span 12;
    }

    &.reverse {
      img, .text {
        grid-column: span 12;
      }
    }
  }
}