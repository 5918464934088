customvideo-component {
    position: relative;
    display: block;
    padding-bottom: 56.25%; 
    width: 100%; 
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 86%;
        border: none;
        object-fit: contain;
    }

    .birthday-paint-background {
        position: absolute;
        width: 100%;
        top: 85%;
        z-index: 10;
    }
  
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none; 
    }
  
    .player-button {
      position: absolute;
      bottom: 20%; 
      right: 2%; 
      background: transparent; 
      border: none; 
      cursor: pointer;
      outline: none; 
      z-index: 1; 
  
      img {
        width: 40px; 
        height: 40px; 
        display: block; 
      }
    }
  }