.snack-quest-container {
  padding: 6rem;
  background-image: url("/docroot/loacker/dist/images/snack-quest-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  // width: 100vw;

  .progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .circle-point {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: bg-color(tertiaryDark);
    text-align: center;
    line-height: 40px;
    margin: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .circle-point:hover {
    background-color: bg-color(brand);
  }

  .circle-point.active {
    background-color: bg-color(brand);
  }

  .snack-quest-heading {
    margin: 18rem 10rem 0 10rem;
    display: flex;

    .snack-quest-heading-text {
      flex: 0.8;
      justify-content: center;
      align-items: center;
      white-space: normal; 
      word-wrap: break-word; 

      h2 {
        font-size: 55px;
        font-weight: 500;
        line-height: 70.4px;
      }

       p {
        font-size: 19px;
        font-weight: 500;
        line-height: 28px;
        color: text-color(secondary);
       }

      .highlight {
        color: text-color(brand);
        font-size: 55px;
        font-weight: 700;
        line-height: 70.4px;
        
      }

      .cursor-text {
        margin-top: 5rem;
        .move-icon {
          width: 24px;
          height: 24px;
          vertical-align: top;
          margin-right: 8px;
        }

         span {
          font-size: 11px;
          font-weight: 500;
          line-height: 14.08px;
          text-transform: uppercase;
          color: text-color(primary);
         }
      }
    
      .discover-more {
        font-size: 13px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
        text-transform: uppercase;
        white-space: normal; 
        word-wrap: break-word;
    
        .discover-more-image {
          width: 24px;
          height: 24px;
          vertical-align: top;
          margin-right: 8px;
        }
      }
    }

    .snack-quest-heading-circle {
      flex: 1;
      justify-content: center;
      align-items: center;
      display: flex;
      text-align: center;

      .progress-container {
        position: relative;
        width: 25vw;
        height: 25vw;
      }

      .progress-bar {
        transform: rotate(-90deg);
      }

      .progress-line {
        fill: none;
        stroke: bg-color(reverse);
        stroke-width: 5;
      }

      .progress-fill {
        fill: none;
        stroke: bg-color(brand);
        stroke-width: 5;
        stroke-dasharray: 0 1000;
        transition: stroke-dasharray 0.3s ease;
      }

      .point {
        fill: bg-color(tertiaryDark);
        cursor: pointer;
        r: 10;
      }

      .point.active {
        fill: bg-color(reverse);
        stroke: bg-color(brand);
        stroke-width: 5;
        r: 19;
      }

      .point.visited {
        fill: bg-color(brand);
        stroke: bg-color(reverse);
        stroke-width: 2;
      }

      .point:not(.active) {
        stroke: white;
        stroke-width: 2;
        r: 10;
      }

      .point-circle {
        transition: all 0.3s ease;
      }

      .inside-circle {
        fill: bg-color(whitesmoke);
      }

      #inside-circle-content {
        color: black;
        font: 18px serif;
        height: 100%;
        transform: rotate(90deg);

        h3 {
          font-size: 15px;
          font-weight: 700;
          line-height: 19.2px;
          color: text-color(brand);
          margin: 0;
        }

        p {
          font-size: 15px;
          font-weight: 500;
          line-height: 22px;
          color: text-color(secondary)
        }


      }
    }
  }

  .snack-quest-list {
    text-align: center;
    margin: 0 10rem;
    padding-top: 1rem;

    &::before {
      content: "";
      width: 72px;
      height: 2px;
      background-color: #D70D1D;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    h2 {
      color: text-color(brand);
      font-size: 28px;
      font-weight: 700;
      line-height: 35.84px;
    }

    .data-container .loacker-tips {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      justify-items: center; 
      justify-content: center; 
      margin-top: 12rem;
      list-style-type: none;
      padding: 0;      

    }
    
    .item-wrapper {
      text-align: center;
      width: 100%;
      margin-bottom: 10rem;
    }

    .data-item {
      width: 17vw;
      height: 17vw;
      border-radius: 50%;
      border: 3px solid bg-color(reverse);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px;
      margin: 0 auto;
      position: relative;
      transition: transform 0.3s ease;
    }

    .data-item {
      .item-image {
        width: 80%;
        height: 60%;
        object-fit: contain;
        max-width: 80%;
        max-height: 60%;
       }
    }

    .top-image {
      position: absolute;
      bottom: 90%;
      width: 75px;
      height: 75px;
      height: auto;
    }

    .data-item h5 {
      font-size: 15px;
      font-weight: 700;
      line-height: 19.2px;
      color: text-color(brand);
    }

    .data-item p {
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      line-height: 28px;
      color: text-color(primary);
    }

    .data-item h5,
    .data-item p {
      margin: 10px 0;
    }

    .item-details {
      margin: 0 auto;
      margin-top: 5rem;
      width: 20vw;

      .item-description {
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        letter-spacing: 4%;
        margin-bottom: 28px;
      }

      .discover-more {
        font-size: 13px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        text-transform: uppercase;
        .discover-more-image {
          width: 24px;
          height: 24px;
          vertical-align: top;
          margin-right: 8px;
        }
      }
    }

    .tag-button {
      background-color: bg-color(tertiary);
      color: text-color(secondaryDarkBrown);
      font-size: 13px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      border-radius: 6px;
      border: 2px solid bg-color(reverse);
      position: absolute;
      top: 96%;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 10%;
    }
  }
}
