.c-hotspot {
  height: calc(50vh - 16px); //NOTE this is temporary for testing purposes
  .image-block {
    .image-trick {
      picture {
        img {
          min-width: 100vw;
          min-height: 50vh;
        }
      }
    }
  }
}