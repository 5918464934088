header {
  background-color: bg-color(brand);
  z-index: 6;
  //position: relative;
  position: sticky;
  top: 0;

  .menu-component {
    & > collapse-component {
      position: fixed;
      top: 56px;
      bottom: 0;
    }

    .upper-bar {
      display: flex;
      justify-content: space-between;
      height: 56px;
      padding: 0 spacer(5);
      margin: 0 auto;
      max-width: 1366px;
      color: text-color(reverse);
      position: relative;
      z-index: 1;

      .left-side {
        display: flex;

        .logo {
          z-index: 2;

          img {
            width: auto;
            height: 90px;
            margin-left: -15px;
            margin-right: 15px;

            [dir='rtl'] & {
              margin-left: 15px;
              margin-right: -15px;
            }
          }

          .pure-goodness {

          }
        }
        .birthday-logo {
          z-index: 2;
          display: flex; 
          justify-content: center;
          align-items: center; 
          height: 100%;

          img {
            width: auto;
            margin-left: 17px;

            [dir='rtl'] & {
              margin-left: 17px;
              margin-right: -17px;
            }
          }
        }

        .brand-expression {
          width: 88px;
        }
      }

      .right-side {
        display: flex;
        align-items: center;

        & > a {
          display: flex;
        }

        & > * {
          &:not(:last-child) {
            margin-right: 24px;

            [dir='rtl'] & {
              margin-right: 0;
              margin-left: 24px;
            }
          }
        }

        .search {
          padding: 0;
        }

        .modal[target='search'] {
          background: bg-color(reverse);
          z-index: 1;
          top: 56px;
          overflow: auto;
        }

        .e-commerce, .store-locator, .search, .menu-button {
          color: text-color(reverse);
        }

        .e-commerce, .store-locator, .search {
          position: relative;

          &:focus, &:hover {
            & > small {
              display: initial;
            }
          }

          small {
            font-family: font-family(primary);
            font-weight: bold;
            text-transform: uppercase;
            background-color: rgba(0, 0, 0, 0.6);
            color: text-color(reverse);
            display: none;
            position: absolute;
            bottom: -48px;
            width: 116px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 4px;
            z-index: 2;

            &:before {
              content: "";
              border: 10px solid rgba(0, 0, 0, 0.6);;
              border-top-color: transparent;
              border-left-color: transparent;
              border-right-color: transparent;
              height: 51px;
              width: 20px;
              position: absolute;
              top: -51px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }

        .search, .menu-button {
          display: flex;
          align-items: center;
        }

        .menu-button {
          padding-right: 40px;
          position: relative;
          text-transform: uppercase;
          font-size: rem(11);
          font-weight: bold;
          letter-spacing: 1px;

          [dir='rtl'] & {
            padding-right: 6px;
            padding-left: 40px;

          }

          &:after {
            @include sprite('h-menu');
            content: "";
            width: 28px;
            height: 18px;
            position: absolute;
            right: 0;

            [dir='rtl'] & {
              right: auto;
              left: 0;

            }
          }
        }

        .trigger-active {
          .menu-button {
            &:after {
              @include sprite(close, (color: #FFF));
              content: "";
              width: 32px;
              height: 32px;
              position: absolute;
              right: 0;

              [dir='rtl'] & {
                right: auto;
                left: 0;

              }
            }
          }
        }

        .search-product-categories-container {
          position: relative;
          max-width: 1128px;
          padding: 0;
          //margin: 0 auto;
          margin: 0;
          grid-column: span 12;
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          grid-column-gap: 24px;

          .search-products-categories {
            margin-bottom: 28px;
            grid-column: 3 / span 8;
            display: grid;
            grid-template-columns: repeat(6, 1fr);

            .search-product-category-link {
              grid-column: auto / span 1;
              position: relative;
              text-align: center;
              display: flex;

              &:after {
                content: "";
                height: 2px;
                width: 24px;
                background: bg-color(brand);
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
              }

              .product-category {
                text-align: center;
                margin: spacer(0.5);
                display: flex;
                flex-direction: column;
                align-items: center;
                max-height: 248px;
                height: 100%;
                width: 100%;
                position: relative;

                img {
                  width: 100%;
                }

                span {
                  text-transform: uppercase;
                  font-weight: bold;
                  display: flex;
                  min-height: 64px;
                  align-items: center;
                  justify-content: center;
                  padding: spacer(2) 0;
                  flex-grow: 1;
                  font-size: rem(14);
                }
              }
            }
          }
        }

        .search-labels {
          position: relative;
          max-width: 1128px;
          margin: 0;
          grid-column: span 12;
          display: grid;
          grid-template-columns: repeat(12, 1fr);

          .search-labels-title {
            margin: spacer(2) 0;
          }

          .search-labels-container {
            grid-column: 3 / span 8;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid border-color(break);
            margin: 0 spacer(-3);
            padding: 0 spacer(3);
          }
        }
      }
    }

    .main-menu {
      display: flex;
      height: calc(100vh - 56px);
      max-height: 100%;
      background-color: bg-color(reverse);
      width: 100vw;

      .left-nav {
        background-color: bg-color(secondary);
        display: flex;
        flex-direction: column;
        max-width: calc(50vw - 300px);
        min-width: 337px;
        flex-grow: 1;

        nav {
          flex-grow: 1;
          display: flex;
          align-items: center;
          padding-left: calc(50vw - 683px);
          margin-left: 40px;

          [dir='rtl'] & {
            padding-right: calc(50vw - 683px);
            padding-left: 0;
            margin-left: 0;
            margin-right: 40px;
          }

          ul {
            padding: 0;

            li {
              margin: 48px 0;
              display: block;
              font-size: rem(24);
              font-weight: bold;
              line-height: rem(32);

              .trigger-active {
                button {
                  color: text-color(brand);
                }
              }

              button {
                font-size: rem(24);
                font-weight: bold;
                line-height: rem(32);
              }
            }
          }
        }

        .social-contacts-links {
          display: flex;
          align-items: center;
          height: 72px;
          background-color: bg-color(secondaryDarker);
          margin: 0;
          padding-left: calc(50vw - 683px);
          padding-right: 48px;
          gap: 20px;

          [dir='rtl'] & {
            //padding-right: calc(50vw - 638px);
            //padding-left: 48px;
          }

          li {
            display: block;
            //margin: 0 20px;

            &:first-child {
              margin-left: 40px;

              [dir='rtl'] & {
                margin-right: 40px;
                margin-left: 20px;
              }
            }

            &:last-child {
              flex-grow: 1;
              font-size: rem(12);
              line-height: rem(16);
              text-transform: uppercase;
              text-align: right;
              margin-right: 0;
            }

            &:not(:last-child) {
              a {
                font-size: rem(24);
              }
            }

            a {
              display: flex;
              justify-content: flex-end;
              color: text-color(reverse);
            }
          }
        }
      }

      .right-nav {
        max-width: 983px;
        padding-right: spacer(5);
        width: 100%;

        [dir='rtl'] & {
          padding-right: 0;
          padding-left: spacer(5);
        }

        .localize {
          height: 56px;
          text-align: right;
          padding-top: 12px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          float: right;

          [dir='rtl'] & {
            float: left;
          }

          button {
            position: relative;
            font-size: rem(11);
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1px;
            padding-right: 40px;

            [dir='rtl'] & {
              padding-right: 0;
              padding-left: 40px;

            }

            &:after {
              @include sprite(language_int);
              content: "";
              width: 24px;
              height: 24px;
              position: absolute;
              right: 0;
              transform: translateY(-50%);
              top: 50%;

              [dir='rtl'] & {
                right: auto;
                left: 0;
              }
            }
          }
        }

        .products-categories-block {
          display: flex;
          flex-direction: column;
          margin: 24px 0 0 12.4%;
          height: calc(100% - 24px);

          [dir='rtl'] & {
            margin: 24px 12.4% 0 0;
          }

          .upper-block {
            flex-grow: 1;
            overflow-y: auto;
            overflow-x: hidden;
          }

          .products-categories {
            margin-bottom: 28px;
            display: flex;
            flex-wrap: wrap;
            margin-right: spacer(-4);
            margin-left: spacer(-4);

            .product-category-link {
              position: relative;
              text-align: center;
              display: flex;
              width: 23%;
              min-width: 169px;
              margin-top: spacer(1);
              margin-right: spacer(4);
              margin-left: spacer(4);

              &:after {
                content: "";
                height: 2px;
                width: 24px;
                background: bg-color(brand);
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
              }

              .product-category {
                text-align: center;
                margin: 4px;
                display: flex;
                flex-direction: column;
                align-items: center;
                max-height: 248px;
                height: 100%;
                width: 100%;
                position: relative;

                img {
                  width: 100%;
                }

                span {
                  text-transform: uppercase;
                  font-weight: bold;
                  display: flex;
                  min-height: 64px;
                  align-items: center;
                  justify-content: center;
                  padding: 16px 37px;
                  flex-grow: 1;
                  min-width: 168px;
                  font-size: rem(14);
                }
              }
            }
          }

          .goto {
            display: flex;
            border-top: 1px solid border-color(break);
            min-height: 72px;

            a {
              &:before {
                margin-right: spacer(2);

                [dir='rtl'] & {
                  margin-right: 0;
                  margin-left: spacer(2);
                  transform: scale(-1);
                }
              }
            }
          }
        }

        .editorial-submenu {
          display: grid;
          grid-template-columns: repeat(2, 65.3% 34.7%);
          grid-column-gap: 14.30%;

          .product-category-link {
            .product-category {
              margin: 0;
              position: relative;
              display: flex;

              span {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: font-family(primary);
                color: text-color(reverse);
                font-size: rem(24);
                font-weight: bold;
                line-height: 32px;
                flex-direction: column;
                padding: 0 94px;
                text-transform: uppercase;
                text-align: center;
                background-color: #0000002b;

                &:after {
                  @include sprite(chevron-link, (color: palette(text, reverse)));
                  content: "";
                  width: 48px;
                  height: 48px;
                  margin-top: spacer(3);
                }
              }
            }
          }

          img {
            width: 100%;
            height: calc(100vh - 56px);
            object-fit: cover;
          }

          ul {
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .menu-links {
            margin: 0;
            padding: 0;
            font-size: rem(14);
            font-weight: bold;
            letter-spacing: 0.2px;
            line-height: rem(16);
            text-transform: uppercase;

            li {
              display: inline-block;
              margin: 32px 0;
            }
          }
        }
      }
    }
  }

  .collapsed {
    display: none;
  }

  .collapse-expanded {
    display: block;
  }

  .language-modal {
    position: fixed;
    top: 144px;
    bottom: 152px;
    min-width: 432px;
    background: bg-color(reverse);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    padding: 0 spacer(7) spacer(7);
    overflow-y: scroll;

    .close-button-component {
      filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.3));
      position: absolute;
      top: 16px;
      right: 10px;

      [dir='rtl'] & {
        right: auto;
        left: 10px;
      }
    }

    .language-modal-title {
      text-align: center;
      margin-top: spacer(9);
    }

    .languages-list {
      display: grid;
      grid-template-columns: 50% 50%;
    }

    .language {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: spacer(1) 0;

      .language-country {
        text-align: center;

        &:before {
          content: "";
          width: 18px;
          height: 18px;
          position: relative;
          display: block;
          border-radius: 50%;
          margin: 0 auto;
          font-size: rem(14);
        }

        &.it {
          &:before {
            @include sprite('country-it');
          }
        }

        &.de {
          &:before {
            @include sprite('country-de');
          }
        }

        &.ca {
          &:before {
            @include sprite('country-ca');
          }
        }

        &.jp {
          &:before {
            @include sprite('country-jp');
          }
        }

        &.us {
          &:before {
            @include sprite('country-us');
          }
        }

        &.ae {
          &:before {
            @include sprite('country-ae');
          }
        }

        &.il {
          &:before {
            @include sprite('country-il');
          }
        }

        &.int {
          &:before {
            @include sprite('language_int');
          }
        }

        &.cn {
          &:before {
            @include sprite('country-cn');
          }
        }

        &.arabia {
          &:before {
            @include sprite('country-arabia');
          }
        }
      }

      .country-span {
        display: block;
        color: text-color(primary);
        font-size: rem(14);
        font-weight: 500;
        letter-spacing: 0.2px;
        margin: spacer(1) 0;

        &.external {
          text-transform: none;

          &:after {
            @include sprite('external');
            content: "";
            width: 16px;
            height: 16px;
            display: inline-block;
            margin-left: 8px;
          }
        }
      }

      a {
        text-transform: uppercase;
        display: inline-block;
        padding: 0 8px;
        font-size: rem(11);

        &:not(:last-child) {
          border-right: 1px solid border-color(primary);

          [dir='rtl'] & {
            border-right: none;
            border-left: 1px solid border-color(primary);

          }
        }

        &.active {
          color: text-color(brand);
          font-weight: bold;
        }
      }
    }
  }

  &.shrinked {
    .menu-component {
      & > collapse-component {
        top: 40px;
      }

      .main-menu {
        height: calc(100vh - 40px);
      }

      .upper-bar {
        height: 40px;

        .brand-expression {
          display: none;
        }

        .right-side {
          .modal[target='search'] {
            top: 40px;
          }
        }

        .left-side {
          .logo {
            img {
              height: 74px;
            }
          }
        }
      }
    }
  }
}
