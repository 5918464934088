body {
  @each $prop, $value in map-get($typography, body) {
    #{$prop}: $value;
  }
}

h1, .header1 {
  @each $prop, $value in map-get($typography, h1) {
    #{$prop}: $value;
  }
}

h2, .header2 {
  @each $prop, $value in map-get($typography, h2) {
    #{$prop}: $value;
  }
}

h3, .header3 {
  @each $prop, $value in map-get($typography, h3) {
    #{$prop}: $value;
  }
}

h4, .header4 {
  @each $prop, $value in map-get($typography, h4) {
    #{$prop}: $value;
  }
}

h5, .header5 {
  @each $prop, $value in map-get($typography, h5) {
    #{$prop}: $value;
  }
}

h6, .header6 {
  @each $prop, $value in map-get($typography, h6) {
    #{$prop}: $value;
  }
}

p, .paragraph {
  @each $prop, $value in map-get($typography, p) {
    #{$prop}: $value;
  }
}

a, .anchor {
  @each $prop, $value in map-get($typography, a) {
    #{$prop}: $value;
  }
}

small, .small {
  @each $prop, $value in map-get($typography, small) {
    #{$prop}: $value;
  }
}

strong, .strong {
  @each $prop, $value in map-get($typography, strong) {
    #{$prop}: $value;
  }
}

figcaption, .description {
  @each $prop, $value in map-get($typography, figcaption) {
    #{$prop}: $value;
  }
}

.header2 {
  letter-spacing: rem(-1.5),
}

.p-big {
  font-size: rem(14);
}

.p-medium {
  font-size: rem(12);
}
