.c-intro-hub {
  background-color: bg-color(reverse);
  padding-top: spacer(7);

  .intro-hub-container {
    grid-template-columns: auto;
    grid-column-gap: 0;
    grid-row-gap: spacer(3);
    padding: 0 24px;
    margin-top: 0;

    .column-left {
      grid-column: span 12;
      padding-top: 0;

      .visual-bg-effect {
        font-size: rem(64);
        line-height: rem(64);
        top: -20px;
        right: 50%;
      }

      .highlighted {
        z-index: 1;
      }

      .triptych-images {
        grid-column: span 12;
        grid-template-columns: repeat(11, 1fr) 24px repeat(6, 1fr) 1fr repeat(4, 1fr);
        grid-template-rows: 0.5fr 0.5fr 20px auto;
        grid-row-gap: 0;
        margin-top: spacer(3);


        picture {
          &.first {
            grid-column: 1/12;
            grid-row: 2/ 4;
            align-self: auto;
          }

          &.second {
            padding-top: 137.7%;
            grid-column: 13/27;
            grid-row: span 2;
          }

          &.third {
            grid-column: 13/20;
            grid-row: 4;

          }
        }
      }
    }

    .column-right {
      grid-column: span 12;
      padding-top: 0;
    }
  }
}