.c-gallery {
  margin: spacer(10) auto;
  max-width: 1128px;
  position: relative;

  slider-componentcst {
    grid-template-columns: auto;
    background-color: bg-color(reverse);

    .navigation {
      left: spacer(4);
      transform: none;

      [dir='rtl'] & {
        right: spacer(4);
        left: auto;
      }
    }
  }

  figure {
    margin: 0;
    width: 100%;

    picture {
      display: block;
      padding-top: 56.25%; //NOTE this is to keep 16:9 aspect ratio
      position: relative;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }

    figcaption {
      padding-left: spacer(4);
      margin-top: spacer(2);

      [dir='rtl'] & {
        padding-right: spacer(4);
        padding-left: 0;
      }
    }
  }
}