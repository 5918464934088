.c-tab {
  .tab-selector {
    border-bottom: 1px solid border-color(break);

    button {
      position: relative;
      padding: spacer(1.5) spacer(1);

      &:before {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        border-bottom: 2px solid border-color(brand);
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
      }

      &:hover {
        &:before {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    }

    .trigger-active {
      button {
        color: text-color(brand);

        &:before {
          transform: scaleX(1);
          visibility: visible;
        }
      }
    }
  }

  .tab-body {
    padding-top: spacer(1);
  }
}