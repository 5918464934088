.c-banner-carousel {
  padding: 40px;
  text-align: center;

  .banner-carousel-container {
    grid-template-columns: auto;
    grid-row-gap: 40px;

    .right-side {
      .svg-image {
        align-self: center;
      }

      slider-componentcst {

        .side-slider {
          grid-row: 1;
          min-height: auto;
          justify-content: center;
        }

        .bullet-div {
          grid-row: 2;
          justify-content: center;
        }
      }
    }
  }
}