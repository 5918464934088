collapse-component {
  &.collapsed {
    &:not(.collapse-expanded) {
      display: none !important;
    }

    &.modal {
      height: 100vh;
      width: 100vw;
      background: #000000b0;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11;

      [dir='rtl'] & {
        right: 0;
      }
    }
  }

  /*
    &.collapse-expanded {
      display: block !important;
    }

   */
}