nav-component {
  position: relative;
  display: block;
  width: 100%;
  border-bottom: 1px solid border-color(breakLight);

  &.secondary-bg {
    background-color: bg-color(secondary);
    border-bottom: none;
  }

  &.sticky {
    position: sticky;
    top: 40px;
    z-index: 5;
  }

  .scrolling-nav {
    height: 80px;
    margin: 0 auto;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0;
    text-align: center;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    ul {
      white-space: nowrap;
      margin: 0 auto;
      position: relative;
      width: 100%;
      height: 80px;
      padding: 0;

      a {
        &.filter {
          display: inline-flex;
          align-items: center;
        }
      }

      .filter {
        position: relative;
        height: 80px;
        min-width: 17%;
        display: inline-block;
        padding: 8px 24px;
        font-family: font-family(primary);
        font-size: rem(14);
        font-weight: 500;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;

        &.article-filter {
          line-height: 42px;
        }

        &.btn {
          padding: 8px 40px 8px 8px;

          [dir='rtl'] & {
            padding: 8px 8px 8px 40px;
          }

          &.without-img {
            padding: 8px 20px;
          }
        }

        img {
          vertical-align: middle;
          height: 64px;
          margin: 0 spacer(1);

          &.article-filter-img {
            width: 40px;
            height: 40px;
            object-fit: cover;
          }
        }

        &.active {
          color: text-color(brand);

          &:before {
            border-bottom: 2px solid border-color(brand);
            transform: scaleX(1);
            visibility: visible;
          }

          &.btn {
            &:after {
              @include sprite(close);
              content: "";
              position: absolute;
              top: 50%;
              right: 15px;
              transform: translateY(-50%);
              width: 16px;
              height: 16px;

              [dir='rtl'] & {
                right: auto;
                left: 15px;
              }
            }
          }
        }

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: bg-color(brand);
          visibility: hidden;
          transform: scaleX(0);
          transition: all 0.3s ease-in-out 0s;

          [dir='rtl'] & {
            right: 0;
            left: auto;
          }
        }

        &:hover {
          cursor: pointer;

          &:before {
            visibility: visible;
            transform: scaleX(1);
          }
        }

        &:focus {
          &:before {
            background: none;
          }
        }
      }
    }

    .prev, .next {
      position: absolute;
      display: flex;
      max-width: 32px;
      height: 64px;
      top: 50%;
      transform: translateY(-50%);
      background-color: bg-color(reverse);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
      z-index: 1;

      &.hide {
        display: none;
      }
    }

    .prev {
      left: 0;
      border-radius: 0 100px 100px 0;
      @supports (-webkit-touch-callout: none) {
        padding-left: 0;
      }

      [dir='rtl'] & {
        right: 0;
        left: auto;
        border-radius: 100px 0 0 100px;
        @supports (-webkit-touch-callout: none) {
          padding-right: 5px;
          padding-left: 5px;
        }
      }

      &:before {
        @include sprite(chevron-right);
        content: "";
        min-width: 24px;
        min-height: 24px;
        transform: rotate(180deg);
        margin: auto;

        [dir='rtl'] & {
          transform: none;
        }
      }
    }

    .next {
      right: 0;
      border-radius: 100px 0 0 100px;
      @supports (-webkit-touch-callout: none) {
        padding-left: 8px;
      }

      [dir='rtl'] & {
        right: auto;
        left: 0;
        border-radius: 0 100px 100px 0;
        @supports (-webkit-touch-callout: none) {
          padding-right: 5px;
        }
      }

      &:before {
        @include sprite(chevron-right);
        content: "";
        min-width: 24px;
        min-height: 24px;
        margin: auto;

        [dir='rtl'] & {
          transform: rotate(180deg);
        }
      }
    }

    &::-webkit-scrollbar { /* WebKit */
      width: 0;
      height: 0;
    }
  }
}
