.c-taste-list {
  position: relative;
  max-width: 1128px;
  margin: spacer(10) auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 24px;
  grid-auto-flow: dense;

  .taste-list-container {
    grid-column-start: 3;
    grid-column-end: 11;

    .header2 {
      position: relative;
      margin-top: 0;

      &:before {
        content: "";
        width: 72px;
        height: 2px;
        background-color: bg-color(brand);
        position: absolute;
        //bottom: 80px;
        top: spacer(-5);
      }
    }

    p {
      margin-bottom: 56px;
    }

    .c-accordion-container {
      column-gap: 0;

      .accordion-trigger {
        grid-column: span 12;
        display: flex;
        //grid-template-columns: repeat(12, 1fr);
        column-gap: spacer(2);

        img {
          vertical-align: middle;
          width: 88px;
          //grid-column: 1/3;
        }

        span {
          //grid-column: 3/12;
        }

        &:after {
          justify-self: flex-end;
        }
      }

      collapse-component {
        &.collapsed {
          grid-column: span 12;
          .accordion-body {
            margin-left: spacer(13);
          }
        }
      }
    }

    .accordion-collapse {

      &.trigger-active {
        .accordion-trigger {

          &:after {
            transform-origin: center center;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}