$typography: (
    h1: (
        font-size: rem(28),
        font-family: font-family(primary),
        font-weight: 700,
        line-height: map($modular-scale, loacker),
        margin: rem(32 0)
    ),
    h2: (
        font-size: rem(28),
        font-family: font-family(primary),
        font-weight: 500,
        line-height: map($modular-scale, loacker),
        margin: rem(26 0)
    ),
    h3: (
        font-size: rem(24),
        font-family: font-family(primary),
        font-weight: 700,
        line-height: map($modular-scale, perfect-fourth),
        margin: rem(20 0)
    ),
    h4: (
        font-size: rem(18),
        font-family: font-family(primary),
        font-weight: 700,
        line-height: map($modular-scale, minor-seventh),
        margin: rem(24 0)
    ),
    h5: (
        font-size: rem(18),
        font-family: font-family(primary),
        font-weight: 400,
        line-height: map($modular-scale, minor-seventh),
        margin: rem(16 0)
    ),
    h6: (
        font-size: rem(18),
        font-family: font-family(primary),
        font-weight: 400,
        line-height: map($modular-scale, minor-seventh),
        margin: rem(16 0)
    ),
    p: (
        font-size: rem(16),
        font-family: font-family(primary),
        line-height: map($modular-scale, minor-seventh),
        margin: rem(12 0)
    ),
    a: (
        font-family: font-family(primary),
    ),
    small: (
        font-family: font-family(primary),
        font-size: rem(12),
        line-height: map($modular-scale, minor-second)
    ),
    strong: (
        color: text-color(brand)
    ),
    figcaption: (
        font-family: font-family(primary),
        font-size: rem(14),
        line-height: map($modular-scale, loacker)
    )
);


h1, .header1 {
  @each $prop, $value in map-get($typography, h1) {
    #{$prop}: $value;
  }
}

h2, .header2 {
  @each $prop, $value in map-get($typography, h2) {
    #{$prop}: $value;
  }
}

h3, .header3 {
  @each $prop, $value in map-get($typography, h3) {
    #{$prop}: $value;
  }
}

h4, .header4 {
  @each $prop, $value in map-get($typography, h4) {
    #{$prop}: $value;
  }
}

h5, .header5 {
  @each $prop, $value in map-get($typography, h5) {
    #{$prop}: $value;
  }
}

h6, .header6 {
  @each $prop, $value in map-get($typography, h6) {
    #{$prop}: $value;
  }
}

p, .paragraph {
  @each $prop, $value in map-get($typography, p) {
    #{$prop}: $value;
  }
}

a, .anchor {
  @each $prop, $value in map-get($typography, a) {
    #{$prop}: $value;
  }
}

small, .small {
  @each $prop, $value in map-get($typography, small) {
    #{$prop}: $value;
  }
}

strong, .strong {
  @each $prop, $value in map-get($typography, strong) {
    #{$prop}: $value;
  }
}

figcaption, .description {
  @each $prop, $value in map-get($typography, figcaption) {
    #{$prop}: $value;
  }
}

.p-big {
  font-size: rem(14);
}

.p-medium {
  font-size: rem(12);
}
