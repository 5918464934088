.section-c-square-list-1 {
  position: relative;
  background-image: url("/docroot/loacker/dist/images/discover-all-our-product-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  width: 100%;
}

.section-c-square-list-2 {
  position: relative;
  background-image: url("/docroot/loacker/dist/images/our-road-to-sustainability-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;

  .c-square-list-bg-element {
    position:absolute;
    bottom: 0%;
  }
}

.c-square-list-1 {
  @extend .c-square-list;
  padding: 0 spacer(3) !important;
}

.c-square-list {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  margin: spacer(10) auto;
  max-width: 1176px;
  //padding: 0 40px;
  position: relative;
  padding: 200px spacer(3);
  // padding: 0 spacer(3);

  &:before {
    content: "";
    width: 72px;
    height: 2px;
    background-color: bg-color(brand);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .header2 {
    grid-row: 1;
  }

  .header6 {
    grid-row: 2;
  }

  .square-listing {
    display: flex;
    padding: 0;
    margin-top: spacer(10);
    justify-content: center;
    flex-wrap: wrap;
    margin-right: spacer(-1.5);
    margin-left: spacer(-1.5);

    li {
      padding: 0 spacer(1.5);
      margin: 0;
      max-width: 50%;
      display: flex;
      flex-basis: 16.6666%;
      justify-content: center;

      .square-item {
        position: relative;
        text-align: center;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        max-width: 168px;

        &:after {
          content: "";
          height: 2px;
          width: 24px;
          background: bg-color(brand);
          position: absolute;
          bottom: 0;
        }

        img {
          width: 100%;
        }

        .description {
          text-transform: uppercase;
          font-weight: bold;
          display: flex;
          height: 64px;
          align-items: center;
          justify-content: center;
          //padding: 0 37px;
          flex-grow: 1;
          margin: 0;
        }
      }
    }

    // NOTE TEST
    /*
        li.ds-block:nth-of-type(7n+1):nth-last-of-type(7n+0), li.ds-block:nth-of-type(7n+1):nth-last-of-type(7n+0) ~ li {
          flex-basis: 25%
        }

        li.ds-block:nth-of-type(8n+1):nth-last-of-type(8n+0), li.ds-block:nth-of-type(8n+1):nth-last-of-type(8n+0) ~ li {
          flex-basis: 25%
        }

        li.ds-block:nth-of-type(9n+1):nth-last-of-type(9n+0), li.ds-block:nth-of-type(9n+1):nth-last-of-type(9n+0) ~ li {
          flex-basis: 20%
        }

        li.ds-block:nth-of-type(10n+1):nth-last-of-type(10n+0), li.ds-block:nth-of-type(10n+1):nth-last-of-type(10n+0) ~ li {
          flex-basis: 20%
        }

*/

    // NOTE EXTRA
    /*
    li.ds-block:nth-of-type(1):nth-last-of-type(7), li.ds-block:nth-of-type(7):nth-last-of-type(7) ~ li {
      flex-basis: 25%;
    }

    li.ds-block:nth-of-type(7n+4):nth-last-of-type(4) ~ li {
      flex-basis: 33%
    }

     */
    /*

    li.ds-block:nth-of-type(7n+1):nth-last-of-type(7n+0), li.ds-block:nth-of-type(7n+1):nth-last-of-type(7n+0) ~ li {
      flex-basis: 25%
    }

    li.ds-block:nth-of-type(8n+1):nth-last-of-type(8n+0), li.ds-block:nth-of-type(8n+1):nth-last-of-type(8n+0) ~ li {
      flex-basis: 25%
    }

    li.ds-block:nth-of-type(9n+1):nth-last-of-type(9n+0), li.ds-block:nth-of-type(9n+1):nth-last-of-type(9n+0) ~ li {
      flex-basis: 20%
    }

    li.ds-block:nth-of-type(10n+1):nth-last-of-type(10n+0), li.ds-block:nth-of-type(10n+1):nth-last-of-type(10n+0) ~ li {
      flex-basis: 20%
    }
*/
  }
}
