.c-recipes-list {
  background-image: url("/docroot/loacker/dist/images/mobile/loacker-recepies-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  // margin-right: 24px;
  // margin-left: 24px;

  .recipes-list-heading {
    grid-column: span 12;
    margin-bottom: 116px;
  }

  .recipes-list {
    grid-column: span 12;
    margin-top: 0;

    .recipe {
      .description {
        font-size: rem(14);
        line-height: rem(16);
        padding: 0 8px 10px;
      }
    }
  }
}



