.c-intro-hub {
  background-color: bg-color(reverse);
  position: relative;

  .intro-hub-container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 40px;
    max-width: 1176px;
    padding: 0 spacer(3);
    margin: spacer(16) auto;
    background-color: bg-color(reverse);

    &:before {
      content: "";
      width: 72px;
      height: 2px;
      background-color: bg-color(brand);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .title {
      text-align: center;
      grid-column: span 12;
      margin-bottom: 0;
    }

    .column-left {
      grid-column: 1/7;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      padding-top: 180px;

      .visual-bg-effect {
        font-size: rem(120);
        font-weight: bold;
        color: text-color(secondaryLighter);
        line-height: rem(120);
        letter-spacing: -2px;
        white-space: nowrap;
        position: absolute;
        top: 0;
        right: 0;

        [dir='rtl'] & {
          right: auto;
          left: 0;
        }
      }

      .highlighted {
        color: text-color(brand);
        font-weight: bold;
        margin-bottom: 0;
      }

      .triptych-images {
        display: grid;
        grid-column-start: 1;
        grid-column-end: 7;
        grid-template-columns: repeat(18, 1fr) 0.6fr repeat(4, 1fr) 0.4fr;
        grid-row-gap: 20px;
        margin-top: spacer(5);

        picture {
          margin: 0;
          position: relative;
          height: 0;
          padding-top: 78.8%;

          img {
            border-radius: 4px;
            width: 100%;
            vertical-align: middle;
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            object-fit: cover;
          }


          &.first {
            grid-row: 1/3;
            grid-column: 1/13;
            align-self: center;
          }

          &.second {
            padding-top: 119.7%;
            grid-column: 14/27;
          }

          &.third {
            grid-column: 14/20;
          }

          &.second, &.third {

          }

          &.first, &.third {
            padding-top: 100%;
          }
        }
      }
    }

    .column-right {
      grid-column: 8/13;
      padding-top: spacer(8);

      h5 {
        margin-bottom: spacer(5);
      }
    }
  }
}