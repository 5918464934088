.c-shop-list {
  background-color: bg-color(tertiaryDarker);
  position: relative;
  border: 1px solid border-color(break);
  border-radius: 8px;
  padding: 0 spacer(2);

  .shop-list-collapse {
    &.collapsed:not(.collapse-expanded) {
      display: block !important;
      max-height: 176px;
      overflow: hidden;

      .shop-btn:nth-child(n+15) {
        visibility: hidden;
      }
    }
  }

  .shop-list-text {
    font-size: rem(16);
    color: text-color(primary);
    margin-bottom: spacer(2);
  }

  .close-button-component {
    display: none;
  }

  .shop-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 spacer(-1);
  }

  .shop-btn {
    flex: 0 0 calc(14.2857% - 16px);
    margin: 0 spacer(1) spacer(2);
    width: 72px;
    min-width: 72px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid border-color(break);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);

    img {
      height: 100%;
      width: 100%;
      border-radius: 3px;
      object-fit: cover;
    }
  }

  .expand-list-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 8px;
    background: linear-gradient(180deg, transparent 0%, #efece2e6 20%, bg-color(tertiaryDarker) 50%);

    &.expand-list-close {
      display: none;
    }

    button {
      position: relative;
      display: flex;
      align-items: center;

      &:before {
        @include sprite(accordion, (color:text-color(primary)));
        content: '';
        width: 24px;
        height: 24px;
        display: block;
        margin-right: spacer(1.5);
      }
    }

    .collapse-close-msg {
      display: none;
    }

    &.trigger-active {
      position: relative;

      button {
        &:before {
          transform: rotate(180deg);
        }
      }

      .collapse-open-msg {
        display: none;
      }

      .collapse-close-msg {
        display: block;
      }
    }
  }
}
