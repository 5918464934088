productcard-component {

  &:before {

  }

  .productcard-container {


    .productcard-package {
      display: none;

      canvas {

      }
    }

    .productcard-component {
      grid-column: span 12;
      padding: 0 spacer(3);

      .productcard-text {
        grid-column: span 12;

        & > * {
          grid-column: span 12;
        }

        .description-title {
          grid-column: span 12;
        }

        .description-text {
          grid-column: span 12;
        }

        .packsize-title {

        }

        .packsize-list {


          .packsize-el {
          }
        }

        .cta-buttons {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          min-height: 64px;
          background: bg-color(reverse);
          z-index: 2;
          margin-bottom: 0;

          .cta-button-li {
            flex: 1;
            margin: spacer(1);

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              text-align: center;
              padding: 0;
            }
          }

          .cta-ecommerce {
            margin: 0;
          }

          .cta-storelocator {
            margin: 0;
          }
        }

        .ing-title {
          margin-top: 20px;

          &:before {

          }
        }

        .productcard-text-image-container {
          grid-column: span 12;
          grid-auto-flow: row;

          &:nth-child(odd) {
            .ingredient-img {
              grid-row: 1;
            }

            .text {

            }
          }

          &:nth-child(even) {
            .ingredient-img {
              grid-column: span 3;
            }

            & > .text {
              grid-column: 4/span 4;
            }
          }

          &:first-child {
            .ingredient-img {
              //grid-column: span 7;
              //max-width: 152px;
            }

            .text {
              //grid-column: span 7;
            }
          }

          .ing-el-title {


          }
        }

      }
    }
  }
}
