productcard-component {
  position: relative;
  background: linear-gradient(to right, transparent 50%, bg-color(secondary) 50%);
  display: block;
  height: 100%;
  width: 100%;

  [dir='rtl'] & {
    background: linear-gradient(to left, transparent 50%, bg-color(secondary) 50%);
  }

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 28px 8px 28px;
    border-color: transparent transparent border-color(secondary) transparent;
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
  }

  .productcard-container {
    max-width: 1128px;

    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;

    .productcard-package {
      grid-row: 1;
      grid-column: 1 / 6;
      //position: sticky;
      //top: 0;
      //display: flex;
      //align-items: center;
      //height: 100vh;
      //display: flex;
      //flex-direction: column;
      //justify-content: center;
      //height: 500px;

      canvas {
        width: 100%;
        position: sticky;
      }
    }

    .productcard-component {
      grid-row: 1;
      grid-column: 4 / 13;
      background-color: bg-color(secondary);
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      grid-column-gap: 24px;
      padding: spacer(10) 0 spacer(13);

      .productcard-text {
        grid-column: 3 / span 7;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-column-gap: 24px;

        & > * {
          grid-column: span 7;
        }

        .description-title {
          grid-column: span 4;
          margin-bottom: spacer(4);
        }

        .description-text {
          margin-bottom: spacer(10);

          a {
            font-weight: bold;
            color: text-color(brand);
          }
        }

        .packsize-title {
          margin-bottom: spacer(3);
        }

        .packsize-list {
          display: flex;
          flex-wrap: wrap;
          margin: spacer(-1) 0 spacer(6) spacer(-1);

          [dir='rtl'] & {
            margin: spacer(-1) spacer(-1) spacer(6) 0;
          }

          .packsize-el {
            text-transform: uppercase;
            font-family: font-family(primary);
            font-weight: bold;
            font-size: rem(14);
            letter-spacing: rem(0.2);
            color: text-color(brand);
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: bg-color(reverse);
            margin: spacer(1);
            text-align: center;
            box-sizing: content-box;
            border: 10px solid border-color(reverse);
          }
        }

        .cta-buttons {
          display: flex;
          margin-bottom: spacer(8);

          .cta-ecommerce {
            margin-right: spacer(3);
          }

          .cta-storelocator {
            margin-right: spacer(3);
          }
        }

        .ing-title {
          position: relative;
          padding-top: spacer(5);
          margin-top: spacer(8);

          &:before {
            content: "";
            width: 72px;
            height: 2px;
            background-color: bg-color(brand);
            position: absolute;
            top: 0;
            left: 0;

            [dir='rtl'] & {
              right: 0;
              left: auto;
            }
          }
        }

        .productcard-text-image-container {
          grid-column: span 7;
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          grid-column-gap: 24px;
          grid-auto-flow: column;
          margin-bottom: spacer(5);

          &:nth-child(odd) {
            .ingredient-img {
              grid-column: 5/span 3;
              width: 100%;
              align-self: center;

              &.modal-header-img {
                grid-column: 3/span 3;
              }
            }

            & > .text {
              grid-column: span 4;
            }
          }

          &:nth-child(even) {
            .ingredient-img {
              grid-column: span 3;
              width: 100%;
              align-self: center;
            }

            & > .text {
              grid-column: 4/span 4;
            }
          }

          .ing-el-title {
            font-size: rem(14);
            font-weight: bold;
            letter-spacing: 1px;
            line-height: rem(16);
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
