#us-facts {
  margin: 0 auto;
  background: #fff;
  border: 1px solid #000;
  padding: 20px 0;
  font-size: 15px;
  line-height: 15px;
  box-shadow: 4px 10px 10px rgba(0, 0, 0, 0.2);
  width: 304px;
  text-align: left;
  color: #000000;

  .facts_title {
    font-size: 35px;
    line-height: 28px;
    border-bottom: 1px solid;
    color: #231f20;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 600;
    padding-bottom: 8px;
  }

  .facts_serving_pre {
    padding-bottom: 3px;
    font-size: 14px;
    line-height: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .facts_serving_size {
    font-size: 16px;
    line-height: 14px;
    padding: 4px 10px;
    font-weight: 600;

    span {
      float: right;
    }
  }

  .facts_box {
    border-top: 13px solid #231f20;
    margin-left: 10px;
    margin-right: 10px;

    &.thin {
      border-width: 5px;
    }

    .fact_row {
      padding-bottom: 2px;
      padding-top: 5px;
      font-size: 14px;
      line-height: 14px;
      border-bottom: 1px solid #838181;
      position: relative;

      &.txt_right {
        text-align: right;
      }

      &.sub {
        padding-left: 18px;
      }

      &.sublv2 {
        padding-left: 36px;
      }

      &.sublv3 {
        padding-left: 34px;
      }

      &.sugars {
        padding-left: 0;
        margin-left: 18px;
        border-bottom: none;
      }

      &.added-sugars {
        padding-left: 0;
        margin-left: 34px;
        border-top: 1px solid #838181;
        border-bottom: none;
      }

      &.protein {
        border-top: 1px solid #838181;
      }

      div {
        float: right;
      }

      &.calories {
        font-size: 1.5em !important;
        line-height: 1em !important;

        .calorieamt {
          padding-bottom: 12px;
          font-size: 36px;
          line-height: 14px;
          float: right;
          font-weight: 600;
        }
      }
    }

    .fact_row_norule {
      padding-bottom: 2px;
      padding-top: 5px;
      font-size: 14px;
      line-height: 14px;
      position: relative;
    }
  }

  .facts_descr {
    padding-right: 10px;
    font-size: 11px;
    line-height: 12px;
    position: relative;
    color: #000;
    border-top: 5px solid;
    margin: 0 10px;
    padding-top: spacer(0.5);
    padding-left: spacer(1);

    &:before {
      content: "*";
      position: absolute;
      left: 0;
    }
  }

  strong {
    color: #000;
  }
}