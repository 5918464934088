.button-primary {
  background-color: buttons(primary, background-color);
  border-color: buttons(primary, border-color);
  color: buttons(primary, color);
  padding: 12px 24px;
  border-radius: 4px;
  font-size: rem(16);
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 24px;
  display: inline-block;

  &:hover,
  &:focus:hover, &:focus {
    background-color: #D62C3A;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    transform: translateY(-1px);
    border-color: buttons(primary, hover-border-color);
    color: buttons(primary, hover-color);
  }

  &:active,
  &:active:focus,
  &:focus {
    //background-color: buttons(primary, hover-background-color);
    //border-color: buttons(primary, hover-border-color);
    //color: buttons(primary, hover-color);
  }
}

.button-secondary {
  background-color: buttons(secondary, background-color);
  border-color: buttons(secondary, border-color);
  color: buttons(secondary, color);
  padding: 12px 24px;
  border-radius: 4px;
  font-size: rem(16);
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 24px;
  display: inline-block;

  &:hover,
  &:focus:hover, &:focus {
    color: buttons(secondary, hover-color);
    border-color: rgba(0, 0, 0, .15);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
  }

  &:active,
  &:active:focus,
  &:focus {
    //background-color: buttons(secondary, active-background-color);
    //border-color: buttons(secondary, active-border-color);
    //color: buttons(secondary, active-color);
  }
}
