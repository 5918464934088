.c-tab-products {
  background-image: url("/docroot/loacker/dist/images/most-wanted-products-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  overflow-x: hidden;
  margin-bottom: spacer(10);
  position: relative;

  .c-tab-products-bg-element {
    position: absolute;
    bottom: 5%;
  }

  .c-tab-products-container {
    margin: 0 auto;
    max-width: 1176px;
    padding: 0 spacer(3);
    position: relative;

    .block {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: 418px auto;
      grid-column-gap: 24px;
      grid-row-gap: 24px;
    }

    .tab-selector {
      grid-column: 1/-1;
      grid-row: 1/2;
      padding-right: 25%;

      [dir='rtl'] & {
        padding-right: 0;
        padding-left: 25%;
      }

      ul {
        padding-top: 80px;
        padding-left: 0;
        margin: 0 0 30px;

        li {
          display: inline-block;
          margin-right: spacer(3);

          [dir='rtl'] & {
            margin-right: 0;
            margin-left: spacer(3);
          }

          button {
            position: relative;
            font-family: font-family(primary);
            font-weight: bold;
            min-height: 40px;
            cursor: pointer;
            color: text-color(secondary);

            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 2px;
              bottom: 0;
              left: 0;
              background-color: bg-color(brand);
              visibility: hidden;
              transform: scaleX(0);
              transition: all 0.3s ease-in-out 0s;

              [dir='rtl'] & {
                right: 0;
                left: auto;
              }
            }

            &:hover {
              color: text-color(brand);

              &:before {
                visibility: visible;
                transform: scaleX(1);
              }
            }
          }

          .trigger-active {
            button {
              color: text-color(brand);

              &:before {
                border-bottom: 2px solid border-color(brand);
                transform: scaleX(1);
                visibility: visible;
              }
            }
          }
        }
      }
    }

    .tab-products-heading {
      grid-column: 1/11;
      grid-row: 1/2;
      padding-right: 25%;
      padding-top: 5%;

      [dir='rtl'] & {
        padding-right: 0;
        padding-left: 25%;
      }
    }

    .tab-image {
      grid-column: 10/13;
      grid-row: 1/3;
      position: relative;
      display: grid;

      picture {
        grid-row: 1/3;

        img {
          max-height: 522px;
          height: 522px;
          width: calc(50vw - 100% - 36px);
          margin-top: -150px;
          object-fit: cover;
        }
      }
    }

    .products-list {
      margin-top: -164px;
      display: grid;
      grid-column: 1/span 12;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 24px;

      .product-element {
        grid-column: auto/span 3;
        margin: 0;
        position: relative;
        text-align: center;

        img {
          width: 100%;
          vertical-align: middle;
          filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.30));
        }

        .special-attr {
          width: 56px;
          height: 56px;
          position: absolute;
          right: 32px;
          top: 32px;
        }

        .description {
          font-weight: bold;
          text-transform: uppercase;

          small {
            text-transform: none;
            font-size: rem(15);
            font-weight: 500;
            color: text-color(brand);
            display: block;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}