.c-hero-editorial {
  position: relative;
  width: 100%;
  background: bg-color(secondary);

  [dir='rtl'] & {
    background: bg-color(secondary);
  }

  .hero-editorial-container {
    grid-template-columns: auto;
    grid-row-gap: spacer(5);
    padding: spacer(3) 0 0;
    background: none;

    [dir='rtl'] & {
      background: none;
    }

    .hero-text {
      grid-column: auto;
      padding: 0 spacer(3);

      [dir='rtl'] & {
        padding: 0 spacer(3);
      }

      .hero-title {
        margin-bottom: spacer(2);
      }

      .scroll-icon {
        display: none;
      }
    }

    .img-container {
      grid-column: auto;
      padding: 0 spacer(3);
      background: linear-gradient(to bottom, bg-color(secondary) 50%, bg-color(reverse) 50%);
    }

  }

  &.wide-img {
    background: none;

    .hero-editorial-container {
      grid-row-gap: 0;
      padding-top: 0;

      .hero-text {
        grid-row-start: 2;
      }

      .img-container {
        grid-row-start: 1;
        background: none;
      }
    }
  }

  &.full-width-img {
    background: none;

    .hero-editorial-container {
      grid-row-gap: 0;
      padding-top: 0;

      .breadcrumb {

      }

      .hero-text {

      }

      .img-pic {


        .img-container {
          height: 41.58vw;
          object-fit: cover;
          background: none;
          padding: 0;
        }
      }
    }
  }
}