slider-componentcst {
  position: relative;
  background-color: bg-color(secondary);
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;

  .scroll-icon {
    position: absolute;
    bottom: 68px;
    padding-left: calc(50% - 605px);
    margin-left: 40px;
    text-transform: uppercase;
    font-size: rem(11);
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    font-family: font-family(primary);
    display: flex;
    align-items: center;

    [dir='rtl'] & {
      padding-right: calc(50% - 605px);
      padding-left: 0;
    }

    &:before {
      @include sprite(scroll);
      content: "";
      width: 24px;
      height: 24px;
      margin-right: spacer(2);

      [dir='rtl'] & {
        margin-right: 0;
        margin-left: spacer(2);
      }
    }
  }

  .container {
    height: 100%;
    width: auto;
    overflow: hidden;
    padding: 0;
    margin: 0;
    max-width: 100%;
    user-select: none;
  }

  &:not(.editmode) {
    ul {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      white-space: nowrap;

      li {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;

        &:first-child {
          display: none;
        }
      }
    }

    .slide {
      display: inline-block;
      height: 50px;
      width: 100%;
    }

    .c-hotspot {
      .image-block {
        grid-column: 1 / 13;
      }
    }

    button {
      position: absolute;

      &.next {
        right: 0;

        [dir='rtl'] & {
          right: auto;
          left: 0;
        }
      }
    }

    .side-slider {
      position: relative;
      padding-left: calc(100% - 605px);

      [dir='rtl'] & {
        padding-right: calc(100% - 605px);
        padding-left: 0;
      }
    }

    .hotspot-heading {
      padding-left: 40px;
      height: 100%;
      position: absolute;
      padding-top: 10.8vh;
      bottom: 0;
      padding-right: 107px;
      padding-bottom: 140px;

      [dir='rtl'] & {
        padding-right: 40px;
        padding-left: 107px;
      }

      .editmode & {
        grid-row: 1;
      }

      &:first-child {
        position: relative;
      }

      &:not(:first-child) {
        opacity: 0;
      }
    }

    p {
      &:not(.header6) {
        color: text-color(primary);
        font-size: rem(20);
        font-weight: 400;
        line-height: 1.6;
        margin-bottom: spacer(7);
      }
    }
  }

  .editmode & {
    .mgnlEditorBar {
      grid-column: 1 / 3;
    }

    .c-hotspot {
      .image-block {
        grid-column: 1 / 13;
      }
    }
  }

  .navigation {
    font-family: font-family(primary);
    font-size: rem(11);
    line-height: rem(16);
    letter-spacing: 1px;
    position: absolute;
    left: 50%;
    bottom: 68px;
    transform: translateX(-50%);
    display: flex;
    width: 132px;
    height: 48px;
    background-color: bg-color(reverse);
    border-radius: 30px;
    justify-content: center;
    align-items: center;

    .prev {
      order: 1;
      position: relative;
      width: 40px;
      height: 24px;

      &:before {
        @include sprite(chevron-right);
        content: "";
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        left: 0;
        transform: rotate(180deg);

        [dir='rtl'] & {
          transform: none;
        }
      }
    }

    .next {
      order: 3;
      position: relative;
      width: 40px;
      height: 24px;

      &:before {
        @include sprite(chevron-right);
        content: "";
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        right: 0;

        [dir='rtl'] & {
          transform: rotate(180deg);
        }
      }
    }

    .slides-counter {
      order: 2;
      [dir='rtl'] & {
        display: inherit;
      }
    }
  }

  .bullet-div {
    display: flex;
    align-items: center;
    grid-row: 2;
    height: 20px;

    .bullet {
      width: 10px;
      height: 10px;
      background-color: bg-color(primary);
      border-radius: 50%;
      margin-right: 16px;
      position: relative;
      padding: 0;
      cursor: pointer;

      &.current-slide, &:hover {
        background-color: #ff000057;
        width: 16px;
        height: 16px;
        margin-right: 13px;
        margin-left: -3px;

        &:before {
          content: "";
          width: 10px;
          height: 10px;
          display: block;
          position: absolute;
          opacity: 1;
          left: 0;
          right: 0;
          border-radius: 50%;
          margin: 0 auto;
          top: 50%;
          transform: translateY(-50%);
          background: red;
        }
      }
    }
  }

  &:not(.editmode) {
    & > .hotspot-heading {
      grid-row: 1;
      padding: 10.8vh 107px 140px calc(100% - 565px);
      height: calc(90vh - 56px);
    }

    & > .c-hotspot {
      grid-row: 1;
    }
  }
}