.c-hero-editorial {
  position: relative;
  width: 100%;
  background: linear-gradient(to right, bg-color(secondary) 50%, bg-color(reverse) 50%);

  [dir='rtl'] & {
    background: linear-gradient(to left, bg-color(secondary) 50%, bg-color(reverse) 50%);
  }

  .hero-editorial-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: spacer(3);
    max-width: 1176px;
    margin: 0 auto;
    padding: spacer(7) spacer(3);
    background: linear-gradient(to right, bg-color(secondary) calc((100% - 264px) / 12 * 8 + (24px * 7)), bg-color(reverse) 50%);

    [dir='rtl'] & {
      background: linear-gradient(to left, bg-color(secondary) calc((100% - 264px) / 12 * 8 + (24px * 7)), bg-color(reverse) 50%);
    }

    .hero-text {
      grid-column-start: 1;
      grid-column-end: 6;
      padding-right: spacer(9);

      [dir='rtl'] & {
        padding-right: 0;
        padding-left: spacer(9);
      }

      strong {
        display: inline-block;
      }

      .category-p {
        display: flex;
        align-items: center;
        color: text-color(brand);
        font-size: rem(14);
        font-weight: 700;
        letter-spacing: rem(0.2);
        line-height: rem(16);
        margin: 0;

        & + h1 {
          margin-top: spacer(1);
        }

        .category-span-img {
          .category-img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            vertical-align: middle;
            margin-right: spacer(1);

            [dir='rtl'] & {
              margin-right: 0;
              margin-left: spacer(1);
            }
          }
        }
      }

      .hero-text-p {
        color: text-color(primary);
      }

      .scroll-icon {
        text-transform: uppercase;
        font-size: rem(11);
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 16px;
        font-family: font-family(primary);
        display: flex;
        align-items: center;
        margin-top: 80px;

        &:before {
          @include sprite(scroll);
          content: "";
          width: 24px;
          height: 24px;
          margin-right: spacer(2);

          [dir='rtl'] & {
            margin-right: 0;
            margin-left: spacer(2);
          }
        }
      }
    }


    .img-container {
      grid-column-start: 6;
      grid-column-end: 13;
      width: 100%;
      align-self: center;
      position: relative;

      img {
        width: 100%;
      }

      .open-video-modal-btn {
        @include sprite(play);
        background-color: bg-color(brand);
        background-size: 22px;
        height: 40px;
        width: 40px;
        display: block;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      
      .open-video-modal-btn {
        @include sprite(play);
        background-color: bg-color(brand);
        background-size: 22px;
        height: 40px;
        width: 40px;
        display: block;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .video-collapse {
        display: flex;
        align-items: center;

        .video-modal {
          width: 100vw;
          position: relative;
          height: 100vh;

          button {
            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(-50%);

            [dir='rtl'] & {
              right: auto;
              left: 0;
            }
          }

          iframe {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: calc(100% - 50px);

            [dir='rtl'] & {
              left: auto;
              right: 0;
            }
          }
        }
      }
    }

    .video-container {
      padding: 0 spacer(3);
      background: linear-gradient(to bottom, bg-color(secondary) 50%, bg-color(reverse) 50%);
      position: relative;
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}