.modal-container {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: bg-color(reverse);
  padding: spacer(5);
  //text-align: center;
  max-width: 552px;
  max-height: 100vh;
  overflow-y: auto;

  &:not(.ingredients-modal) {
    text-align: center;
  }

  &.ingredients-modal {
    transform: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: none;

    .header2 {
      &:first-child {
        padding-top: 0;

        &:before {
          content: "";
          width: 72px;
          height: 2px;
          background-color: bg-color(brand);
          position: absolute;
          top: -40px;
        }
      }
    }
  }

  .modal-img {
    max-width: 168px;
  }

  .header3 {
    position: relative;

    small {
      text-transform: none;
      font-size: rem(15);
      font-weight: 500;
      color: text-color(brand);
      display: block;
      margin-bottom: 12px;
    }
  }

  .header4 {
    margin-top: spacer(8);
  }

  p {
    position: relative;
    font-size: rem(16);
    letter-spacing: 0.2px;
    line-height: 24px;

    &.gnome-description {
      margin-top: 64px;
    }
  }

  .button-primary {
    margin-top: spacer(4);
    display: block;
  }

  button {
    position: absolute;
    right: spacer(2);
    top: spacer(2);
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.3));
    padding: 0;

    [dir='rtl'] & {
      right: auto;
      left: spacer(2);
    }
  }
}

.modal-img-container {
  position: relative;
  max-width: 1128px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: spacer(3);
  margin: spacer(3) auto;

  img {
    &.modal-header-img {
      width: 100%;
      grid-column: 3/span 3;
    }
  }
}