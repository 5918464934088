.c-recipes-list {
  position: relative;
  background-image: url("/docroot/loacker/dist/images/loacer-recepies-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  margin: spacer(16) auto;
  max-width: 1176px;
  padding: 0 spacer(3);
  // padding: 100px spacer(3);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  .recipes-list-heading {
    grid-column-start: 1;
    grid-column-end: 6;
    top: 0;

    a {
      margin-top: 32px;
    }
  }

  .recipes-list {
    display: grid;
    grid-column-start: 7;
    grid-column-end: 13;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    align-self: flex-start;
    margin-top: 42px;

    .recipe {
      margin: 0;
      position: relative;
      margin-top: -80px;
      height: 0;
      padding-top: 78.8%;

      img {
        border-radius: 4px;
        width: 100%;
        vertical-align: middle;
        //filter: brightness(15%) grayscale(10%);
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        object-fit: cover;
      }


      .description {
        position: absolute;
        color: text-color(reverse);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: flex-end;
        font-size: rem(18);
        font-weight: bold;
        line-height: 24px;
        padding: 0 24px 16px;
        margin: 0;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #00000060);
      }

      &:nth-of-type(even) {
        margin-top: 0;
        grid-column-start: 2;

      }
    }
  }
}



