.c-search-boxes {
  position: relative;
  max-width: 1128px;
  padding: 0;
  margin: 0;
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 24px;

  .c-search-boxes-list {
    grid-column: 3 / span 8;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: spacer(3);

    .search-box {
      display: block;
      grid-column: auto / span 1;

      .search-box-link {
        display: block;
        position: relative;
        padding-top: 75.867%;

        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .search-box-caption {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          color: text-color(reverse);
          text-transform: uppercase;
          line-height: rem(32);
          font-size: rem(20);
          text-align: center;
          background-image: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.3));

          &:after {
            @include sprite(chevron-link, (color: palette(text, reverse)));
            content: "";
            width: 32px;
            height: 32px;
            margin-top: spacer(3);
          }
        }
      }
    }
  }
}