.c-text-image {
  position: relative;
  margin: spacer(6) auto;

  .text-image-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
    grid-auto-flow: dense;
    margin: 0 auto;
    max-width: 1128px;

    img {
      width: 100%;
      align-self: start;
      grid-column-start: 3;
      grid-column-end: 7;
    }

    .text {
      grid-column-start: 7;
      grid-column-end: 11;

      h4 {
        margin-top: 0;
      }

      p {
        color: text-color(secondary);
      }
    }

    &.reverse {
      img {
        grid-column-start: 7;
        grid-column-end: 11;
      }

      .text {
        grid-column-start: 3;
        grid-column-end: 7;
      }
    }
  }
}