.c-store-info {
  width: 100%;
  padding: spacer(10) 0;

  .store-info-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
    max-width: 1128px;
    margin: 0 auto;

    .store-description {
      grid-column-start: 1;
      grid-column-end: 8;
    }

    .store-info {
      position: relative;
      grid-column-start: 9;
      grid-column-end: 13;

      &:before {
        content: "";
        width: 2px;
        height: 100%;
        background-color: bg-color(brand);
        position: absolute;
        left: -24px;
      }

      .address, .hours, .phone {
        margin-left: 34px;

        &:before {
          content: "";
          width: 24px;
          height: 24px;
          margin-right: 16px;
          display: inline-block;
          position: absolute;
          left: 0;
        }
      }

      .address {
        &:before {
          @include sprite(map-pin);
        }
      }

      .hours {
        &:before {
          @include sprite(clock);
        }
      }

      .phone {
        &:before {
          @include sprite(phone);
        }
      }
    }
  }
}