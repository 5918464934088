.section-c-square-list-1 {
  position: relative;
  background-image: url("/docroot/loacker/dist/images/mobile/discover-all-our-products-mobile.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  width: 100%;
}

.section-c-square-list-2 {
  position: relative;
  background-image: none;
}

.c-square-list-1 {
  padding: 0 24px;

  .square-listing {
    margin-top: 0;

    li {
      flex-basis: 100%;
      margin-bottom: spacer(2);
    }

    .square-item {
      max-width: 152px;
    }
  }
}

.c-square-list {
  padding: 0 24px;

  .square-listing {
    margin-top: 0;

    li {
      flex-basis: 100%;
      margin-bottom: spacer(2);
    }

    .square-item {
      max-width: 152px;
    }
  }
}