countdown-component {
    .countdown-wrapper ol li {
        font-size: 2rem;
        padding: 0.8rem;
  
        span {
          font-size: 2rem;
        }
    }
  }
  
  