.birthday-banner1 {
    background-image: url("/docroot/loacker/dist/images/birthday-banner-1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 100%;
    height: 30vh;
    display: flex;
}

.birthday-banner2 {
    background-image: url("/docroot/loacker/dist/images/birthday-banner-2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 100%;
    height: 30vh;
    display: flex;
}

