.c-double-col {
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 54px;
  padding: 0 24px;
  margin: spacer(3) auto;

  .double-col-main {
    grid-column: span 2;
    grid-row: 2;
  }

  .aside-info {
    grid-column: span 2;
    grid-row: 1;
    position: relative;

    &:before {
      content: none;
    }

    .time {
      &:before {


      }
    }

    .lv {
      &:before {

      }

      &.max {
        &:before {

        }
      }
    }

    .recipe-related-products-title {
      margin: 0;
    }

    .accordion-collapse {
      &.accordion-trigger {
        &.trigger-active {
          color: text-color(brand);

          &:after {
            transform-origin: center center;
            transform: rotate(180deg);
          }
        }
      }
    }

    .accordion-trigger {
      position: relative;
      grid-column: 3/span 8;
      grid-row: 1;
      height: 80px;
      padding: 0;
      cursor: pointer;
      border-top: 1px solid border-color(breakLight);
      border-bottom: 1px solid border-color(breakLight);
      font-size: rem(16);
      font-weight: bold;
      letter-spacing: 0.2px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        padding: 0;
      }

      & + .accordion-trigger {
        border-top: none;
      }

      &.trigger-active {
        border-bottom: none;
      }

      span {
        flex-grow: 1;
        display: flex;
      }

      &:hover {
        color: text-color(brand);
      }

      &:after {
        @include sprite(accordion);
        content: "";
        position: relative;
        color: text-color(brand);
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        display: block;
        transition-duration: .5s;
        transition-property: transform;
        right: 0;
        background-position: right;
        margin-right: 10px;
      }
    }

    collapse-component {
      &.collapsed {
        grid-column: 3/span 7;
        grid-row: 2;

        .accordion-body {
          .header6, p {
            font-size: rem(16);
            line-height: rem(24);
          }

          .header6 {
            font-weight: bold;
          }

          & > p {
            margin-bottom: spacer(4);
          }

          .text-image-accordion {
            margin: 0;
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            column-gap: 24px;

            picture {
              grid-column: 1/3;

              img {
                width: 100%;
                border-radius: 4px;
              }
            }

            .description {
              grid-column: 3/8;

              > *:first-child {
                margin-top: 0;
              }
            }
          }
        }

        &.collapse-expanded {
          & + .accordion-trigger {
            border-top: none;
          }

          .accordion-body {
            padding-bottom: spacer(3);
            border-bottom: 1px solid border-color(breakLight);
          }
        }
      }
    }
  }
}