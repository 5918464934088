.page-header {
  background-color: bg-color(secondary);
  min-height: 408px;
  //display: flex;
  width: 100%;
  //justify-content: center;
  //align-items: center;
  display: inline-block;

  .container {
    max-width: 600px;
    text-align: center;
    padding: 0 15px;
    margin: 0 auto;
  }
}

.navbar-year {
  position: sticky;
  top: 40px;
  display: flex;
  justify-content: center;
  height: 80px;
  background-color: bg-color(secondary);
  padding: 0 40px;
  width: 100%;
  z-index: 1;

  a {
    position: relative;
    //display: inline-block;
    //flex: 1;
    //top: 50%;
    //transform: translateY(-50%);
    //max-width: 72px;
    height: 80px;
    font-family: font-family(primary);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 80px;
    text-align: center;
    padding: 0 spacer(3);

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: bg-color(brand);
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out 0s;
    }

    &:hover {
      color: text-color(brand);

      &:before {
        visibility: visible;
        transform: scaleX(1);
      }
    }

    &.active {
      color: text-color(brand);

      &:before {
        border-bottom: 2px solid border-color(brand);
        transform: scaleX(1);
        visibility: visible;
      }

      &:after {
        @include sprite(close);
        content: "";
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
      }
    }
  }

  &.reverse {
    background-color: bg-color(reverse);
    border-bottom: 1px solid border-color(breakLight);
  }
}