.c-related-products {
  margin: spacer(13) auto;
  text-align: left;
  overflow: hidden;
  //padding: 0 24px;
  background: none;

  [dir='rtl'] & {
    text-align: right;
  }

  .related-products-container {
    width: 100vw;
    display: block;

    .header2 {
      padding: spacer(5) spacer(5);
      margin: 0;
      background-color: bg-color(secondary);
    }

    .square-listing {
      white-space: nowrap;
      overflow-x: scroll;
      display: block;
      margin-top: 0;

      li {
        display: inline-block;
        min-width: 160px;
        width: 25%;

        &:first-child:nth-last-child(1) {
          /* -or- li:only-child { */
          picture {

            &:after {
              right: calc(-100vw + (100% * 1));
              width: calc(100vw - (100% * 1));
              [dir='rtl'] & {
                right: auto;
                left: calc(-100vw + (100% * 1));
              }
            }
          }
        }

        /* two items */
        &:first-child:nth-last-child(2) {

          & ~ li:last-child {
            picture {
              &:after {
                right: calc(-100vw + (100% * 2));
                width: calc(100vw - (100% * 2));
                [dir='rtl'] & {
                  right: auto;
                  left: calc(-100vw + (100% * 2));
                }
              }
            }
          }
        }

        /* three items */
        &:first-child:nth-last-child(3) {

          & ~ li:last-child {
            picture {
              &:after {
                right: calc(-100vw + (100% * 3));
                width: calc(100vw - (100% * 3));
                [dir='rtl'] & {
                  right: auto;
                  left: calc(-100vw + (100% * 3));
                }
              }
            }
          }
        }

        /* four items */
        &:first-child:nth-last-child(4) {

          & ~ li:last-child {
            picture {
              &:after {
                right: calc(-100vw + (100% * 4));
                width: calc(100vw - (100% * 4));
                [dir='rtl'] & {
                  right: auto;
                  left: calc(-100vw + (100% * 4));
                }
              }
            }
          }
        }

        &:not(:last-child) {
          margin-right: 0;

          [dir='rtl'] & {
            margin-left: 0;
          }
        }

        .square-item {
          picture {
            &:before {
              content: none;
            }

            img {
              background: linear-gradient(to bottom, bg-color(secondary) 50%, bg-color(reverse) 50%);
            }
          }

          .description {


            small {

            }
          }
        }
      }
    }
  }
}