.c-shop-list {
  padding: 0 spacer(1);
  margin: 0 -25px spacer(4);
  border-radius: 0px;
  border-right: none;
  border-left: none;

  .shop-list-collapse {
    &.collapsed:not(.collapse-expanded) {


      .shop-btn:nth-child(n+15) {

      }
    }

    &.collapse-expanded {
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 24px;
      max-width: 100%;
      background-color: bg-color(reverse);
      position: fixed;
      z-index: 6;
      display: flex;
      flex-direction: column;

      .close-button-component {
        display: inline-block;
        align-self: flex-end;
      }

      .shop-list {
      }
    }
  }

  .shop-list-text {
    text-align: center;
  }

  .shop-list {
    max-width: 375px;
    justify-content: center;
    margin: 0 auto;
  }

  .shop-btn {
    flex: 0 0 calc(25% - 16px);
    margin: 0 spacer(0.75) spacer(2);
  }

  .expand-list-btn {


    &.expand-list-close {

    }

    button {


      &:before {

      }
    }

    .collapse-close-msg {

    }

    &.trigger-active {

      button {
        &:before {

        }
      }

      .collapse-open-msg {

      }

      .collapse-close-msg {

      }
    }
  }
}
