.row {
  display: flex;
  flex-wrap: wrap;

  .col {
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    margin: 15px;
    text-align: left;
  }
}
