.birthday-countdown-container {
  background-image: url("/docroot/loacker/dist/images/birthday-countdown-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  background-position: center;
  position: relative;
  width: 100%;
  height: 80vh;
  display: flex;

  .birthday-countdown-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (--between-sm-md) {
      countdown-component {
        top: 20% !important;
        scale: 1.3;
      }
    }

    countdown-component {
      position: absolute;
      top: 25%;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    

    .flipdown .rotor {
      font-size: 7rem;
      font-weight: 700;
      // font-family: $font1;
      border-radius: 5.91px;
      // border: 3.48px solid bg-color(reverse);

    }

    .flipdown .rotor,
    .flipdown .rotor-leaf,
    .flipdown .rotor-leaf-front,
    .flipdown .rotor-leaf-rear,
    .flipdown .rotor-top,
    .flipdown .rotor-bottom,
    .flipdown .rotor:after {
      width: 51.74px;
    }

    .flipdown .rotor-group:nth-child(1):before,
    .flipdown .rotor-group:nth-child(1):after {
      content: "";
      position: absolute;
      left: 120px;
      height: 8px;
      width: 8px;
      background-color: black;
      border-radius: 50%;
    }

    .flipdown .rotor-group:nth-child(1):before {
      bottom: 35px;
    }

    .flipdown .rotor-group:nth-child(1):after {
      bottom: 55px;
    }

    .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):before,
    .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
      left: 120px;
    }

    .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):before {
      bottom: 35px;
      height: 8px;
      width: 8px;
    }

    .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
      bottom: 55px;
      height: 8px;
      width: 8px;
    }

    .flipdown .rotor-leaf-front,
    .flipdown .rotor-top {
      line-height: 103.48px;
    }

    .flipdown .rotor-leaf,
    .flipdown .rotor {
      height: 103.48px;
    }

    .flipdown .rotor-leaf-front,
    .flipdown .rotor-leaf-rear,
    .flipdown .rotor-top,
    .flipdown .rotor-bottom,
    .flipdown .rotor:after {
      height: 51.74px;
    }

    .flipdown__theme-dark .rotor-group:last-child {
      display: none;
    }

    .flipdown.flipdown__theme-dark .rotor-group:nth-child(3):before {
      display: none;
    }
    .flipdown.flipdown__theme-dark .rotor-group:nth-child(3):after {
      display: none;
    }

    .flipdown__theme-dark .rotor-group-heading::before {
      display: inline-block;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-image: url("/docroot/loacker/dist/images/countdown-underline.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      padding: 30px;
      margin-top:-2rem;
      box-sizing: border-box;
      text-transform: uppercase;
    }

    .flipdown__theme-dark {
      margin-left: auto;
    }

    .flipdown .rotor-group {
      position: relative;
    }

    .flipdown .rotor-group-heading {
      position: absolute;
      top: 100%;
      width: 100%;
      margin-bottom: 5px;
    }

    .flipdown .rotor-group-heading::before {
      color: text-color(tertiaryDark);
    }

    .flipdown__theme-dark .rotor,
    .flipdown__theme-dark .rotor-top,
    .flipdown__theme-dark .rotor-bottom,
    .flipdown__theme-dark .rotor-leaf-rear,
    .flipdown__theme-dark .rotor-leaf-front {
      background-color: bg-color(tertiary) !important;
      color: text-color(primary);
    }

    .flipdown__theme-dark .rotor-top,
    .flipdown__theme-dark .rotor-bottom,
    .flipdown__theme-dark .rotor-leaf-rear,
    .flipdown__theme-dark .rotor-leaf-front {
      border-radius: 5.91px;
      color: text-color(primary);
    }

    .flipdown__theme-dark[data-before="Seconds"] {
      display: none !important;
    }

    .flipdown__theme-dark .rotor[data-before="Seconds"],
    .flipdown__theme-dark .rotor-top[data-before="Seconds"],
    .flipdown__theme-dark .rotor-bottom[data-before="Seconds"],
    .flipdown__theme-dark .rotor-leaf-rear[data-before="Seconds"],
    .flipdown__theme-dark .rotor-leaf-front[data-before="Seconds"],
    .flipdown__theme-dark .rotor-group[data-before="Seconds"],
    .flipdown__theme-dark .rotor-group-heading[data-before="Seconds"] {
      display: none !important;
    }

    .countdown-banner-woman {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .countdown-banner-element {
      width: 160px;
      height: 101px;
      position: absolute;
      bottom: 50%;
      left: 0;
    }
  }
  .birthday-text-wrapper {
    flex: 1;
    display: flex;
    justify-content: start;
    align-items: center;

    .text-container {
      h1 {
        font-weight: 700;
        font-size: 57px;
        line-height: 70px;
        color: text-color(reverse);
        margin: 0;
      }

      span {
        font-weight: 500;
        font-size: 57px;
        line-height: 70px;
        color: text-color(reverse);
      }

      p {
        font-weight: 700;
        font-size: 20px;
        line-height: 31px;
        color: text-color(reverse);
        margin: 3.5rem 0rem 3.5rem 0rem;
      }

      button {
        height: auto;
        border-radius: 4px;
        padding: 17px 23px 17px 23px;
        background-color: bg-color(reverse);
        font-weight: 700;
        font-size: 17px;
        color: text-color(primary);
      }
    }
  }
}

.birthday-countdown-container {
  background-image: url("/docroot/loacker/dist/images/mobile/component-mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  background-position: center;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column; 
  align-items: center; 

  .birthday-countdown-wrapper {
    width: 100%;
    height: 100vh;
    justify-content: center;

    countdown-component {
      position: absolute;
      top: 35%;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    // @media (--between-xs-sm) {
    //   countdown-component {
    //     top: 15%;
    //   }
    // }

    .flipdown.flipdown__theme-dark {
      width: 100%;
      height: 30%;
      display: flex;
      justify-content: center;
      position: unset;
      top: 0;
      left: 0;
    }
    .flipdown .rotor {
      font-size: 4rem;
      font-weight: 700;
      font-family: $font1;
      border-radius: 5.91px;
      // border: 3.48px solid bg-color(reverse);

    }

    .flipdown .rotor,
    .flipdown .rotor-leaf,
    .flipdown .rotor-leaf-front,
    .flipdown .rotor-leaf-rear,
    .flipdown .rotor-top,
    .flipdown .rotor-bottom,
    .flipdown .rotor:after {
      width: 35.95px;
    }

    .flipdown .rotor-group:nth-child(1):before,
    .flipdown .rotor-group:nth-child(1):after {
      content: "";
      position: absolute;
      left: 82px;
      height: 6px;
      width: 6px;
      background-color: black;
      border-radius: 50%;
    }

    .flipdown .rotor-group:nth-child(1):before {
      bottom: 25px;
    }

    .flipdown .rotor-group:nth-child(1):after {
      bottom: 40px;
    }

    .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):before,
    .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
      left: 82px;
    }

    .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):before {
      bottom: 25px;
      height: 6px;
      width: 6px;
    }

    .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
      bottom: 40px;
      height: 6px;
      width: 6px;
    }

    .flipdown .rotor-leaf-front,
    .flipdown .rotor-top {
      line-height: 71.9px;
    }

    .flipdown .rotor-leaf,
    .flipdown .rotor {
      height: 71.9px;
    }

    .flipdown .rotor-leaf-front,
    .flipdown .rotor-leaf-rear,
    .flipdown .rotor-top,
    .flipdown .rotor-bottom,
    .flipdown .rotor:after {
      height: 35.95px;
    }


    .flipdown__theme-dark .rotor-group:last-child {
      display: none;
    }

    .flipdown.flipdown__theme-dark .rotor-group:nth-child(3):before {
      display: none;
    }
    .flipdown.flipdown__theme-dark .rotor-group:nth-child(3):after {
      display: none;
    }

    .flipdown__theme-dark .rotor-group-heading::before {
      display: inline-block;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-image: url("/docroot/loacker/dist/images/countdown-underline.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      margin-top: -1rem;
      padding: 24px;
      box-sizing: border-box;
      font-size: 15px;
      font-weight: 500;
    }

    .flipdown .rotor-group {
      position: relative;
    }

    .flipdown .rotor-group-heading {
      position: absolute;
      top: 100%;
      width: 100%;
      right: 20%;
      margin-bottom: 5px;
    }

    .flipdown .rotor-group-heading::before {
      color: text-color(tertiaryDark);
    }

    .flipdown__theme-dark .rotor,
    .flipdown__theme-dark .rotor-top,
    .flipdown__theme-dark .rotor-bottom,
    .flipdown__theme-dark .rotor-leaf-rear,
    .flipdown__theme-dark .rotor-leaf-front {
      background-color: bg-color(tertiary) !important;
      color: text-color(primary);
    }

    .flipdown__theme-dark .rotor-top,
    .flipdown__theme-dark .rotor-bottom,
    .flipdown__theme-dark .rotor-leaf-rear,
    .flipdown__theme-dark .rotor-leaf-front {
      // border: 3.48px solid bg-color(reverse);
      border-radius: 5.91px;
      color: text-color(primary);
    }

    .flipdown__theme-dark[data-before="Seconds"] {
      display: none !important;
    }

    .flipdown__theme-dark .rotor[data-before="Seconds"],
    .flipdown__theme-dark .rotor-top[data-before="Seconds"],
    .flipdown__theme-dark .rotor-bottom[data-before="Seconds"],
    .flipdown__theme-dark .rotor-leaf-rear[data-before="Seconds"],
    .flipdown__theme-dark .rotor-leaf-front[data-before="Seconds"],
    .flipdown__theme-dark .rotor-group[data-before="Seconds"],
    .flipdown__theme-dark .rotor-group-heading[data-before="Seconds"] {
      display: none !important;
    }

    .countdown-banner-woman {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .countdown-banner-element {
      position: absolute;
      bottom: 50%;
      left: 0;
    }
  }
  .birthday-text-wrapper {
    width: 100%; 
    justify-content: flex-start; 

    .text-container {
      margin-left: 2rem;
      margin-top: 2rem;
      margin-right: 2rem;
      width: 100%;

      h1 {
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        color: text-color(reverse);
      }

      span {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: text-color(reverse);
      }

      p {
        font-weight: 500;
        font-size: 20px;
        line-height: 31px;
        color: text-color(reverse);
        margin: 2rem 0rem 2rem 0rem;
      }

      button {
        height: auto;
        border-radius: 4px;
        padding: 17px 23px 17px 23px;
        background-color: bg-color(reverse);
        font-weight: 700;
        font-size: 17px;
        width: 100%;

        a {
          color: text-color(brand);
          font-size: 17px;
          font-weight: 700;
          line-height: 21.76px;
        }
      }
    }
  }
}


