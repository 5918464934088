.c-related-news {

  .related-news-container {
    margin-left: spacer(3);

    [dir='rtl'] & {
      margin-right: spacer(3);
      margin-left: 0;
    }

    div {
      grid-column: span 12;
      margin-right: spacer(3);

      [dir='rtl'] & {
        margin-right: 0;
        margin-left: spacer(3);
      }
    }

    a {
      margin-bottom: 24px;
    }

    nav-component {
      grid-column: span 12;
      margin: 0;
      width: 100%;

      .scrolling-nav {
        .prev {
          display: none;
        }

        .next {
          display: none;
        }
      }
    }
  }
}