search-component {
  grid-template-columns: repeat(2, 1fr);
  margin: 80px 24px;

  .search-form {
    grid-column: span 2;
    margin-right: 0;
    margin-left: 0;

    .search-input-wrapper {


      &:before {
        margin: spacer(1.5);
      }

      &:after {
        left: 48px;
      }

      .search-form-input {
        height: 48px;
        padding-right: 56px;
        padding-left: 56px;

        &::placeholder {
          font-size: rem(12);
          letter-spacing: 0.3px;
          line-height: 14px;
        }
      }

      .input-reset-btn {
        right: 8px;

        [dir='rtl'] & {
          right: auto;
          left: 8px;
        }
      }
    }
  }

  .search-listing {
    grid-column: span 2;
    margin: 0;

    .search-group-item {


      .list-group-title {
        padding: 0 spacer(2) spacer(1);
      }

      .search-sublist {
        padding: 0;

        .search-item-link {
          grid-column-gap: 16px;

          .sublist-item-img {

          }

          .sublist-item-text {
            font-size: rem(14);
            line-height: rem(24);
          }
        }
      }
    }
  }
}