.c-hotspot {
  display: grid;
  //min-height: 600px;
  min-height: 100%;
  height: calc(90vh - 56px);
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 2.08%;
  grid-row-gap: 24px;
  grid-auto-flow: dense;

  .hotspot-heading {
    grid-column-start: 1;
    grid-column-end: 7;
    position: sticky;
    top: 0;
  }

  .image-block {
    grid-column: 7/13;
    margin: 0;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image-trick {
      select {
        position: relative;
        bottom: -50px;
        left: 0;
      }

      position: relative;

      picture {
        img {
          vertical-align: middle;
          //width: 100%; //NOTE to use with percentage
          //height: 100%; //NOTE to use with percentage
          //object-fit: cover;
          min-width: 50vw;
          min-height: 90vh;
        }
      }

      .hotspot-pin {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        @include sprite(hotspot);
        transform: translate(-50%, -50%);

        &.edit-mode {
          cursor: default;
        }

        &.animate {
          animation: pulse 5s ease-in-out 3s 5;
        }

        .delete-item {
          position: absolute;
          top: -20px;
          height: 20px;
          width: 20px;
          left: 0;
          @include sprite(close);
        }
      }
    }

    .video-trick {
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  10% {
    transform: translate(-50%, -50%) scale(2);
  }
  25% {
    transform: translate(-50%, -50%) scale(1);
  }
  30% {
    transform: translate(-50%, -50%) scale(1);
  }
  40% {
    transform: translate(-50%, -50%) scale(2);
  }
  55% {
    transform: translate(-50%, -50%) scale(1);
  }
}