.plp-sentinel {
  top: -100px;
  height: 1px;
}

.c-plp {
  overflow: hidden;

  .c-plp-container {
    display: grid;
    grid-column: 1/span 12;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
    max-width: 1128px;
    position: relative;
    //margin: -136px auto 0;
    margin: 0 auto;
    min-height: 100vh;
    //padding-top: 136px;
    grid-template-rows: min-content;

    sentinel-component {
      position: absolute;
      top: -120px;
      padding-top: 120px;
      height: 10px;
      //height: calc(50% + 130px);
      width: 100%;
    }

    .shrinked ~ & {
      //padding-top: 120px;
      //margin-top: -120px;
    }

    .plp-heading {
      grid-column: 1/8;

      .header2 {
        margin-top: spacer(7);
        margin-bottom: 0;

        span {
          display: block;
          color: text-color(brand);
          font-family: font-family(primary);
          font-size: rem(18);
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
          text-transform: uppercase;
        }
      }

      .header5 {
        margin-top: 0;
      }

      .accordion-collapse {
        &.accordion-trigger {

          .icon-only-accordion-btn {
            padding: 0;

            &:after {
              @include sprite(accordion);
              content: "";
              position: relative;
              color: text-color(brand);
              width: 24px;
              height: 24px;
              display: block;
              transition-duration: .5s;
              transition-property: transform;
              left: 0;
              background-position: right;
            }
          }

          &.trigger-active {
            .icon-only-accordion-btn {
              &:after {
                transform-origin: center center;
                transform: rotate(180deg);
                //-webkit-transform: rotate(180deg);
                //-ms-transform: rotate(180deg);
              }
            }
          }
        }
      }
    }

    .products-list {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 24px;
      grid-column: 1/8;
      grid-auto-rows: max-content;
      grid-row-gap: 16px;

      .product-element {
        grid-column: auto/span 2;
        margin: 0;
        position: relative;
        text-align: center;

        img {
          width: 100%;
          vertical-align: middle;
          filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.3));
        }

        .special-attr {
          width: 48px;
          height: 48px;
          position: absolute;
          right: 12px;
          top: 12px;
        }

        .description {
          font-weight: bold;
          line-height: 16px;
          text-transform: uppercase;
        }
      }
    }


    .aside-container {
      grid-column: 9/span 4;
      grid-row: 1 / span 2;
      position: relative;

      .aside-img {
        position: absolute;
        clip: rect(0, 100vw, auto, 0);
        height: 100%;
        left: 0;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        width: calc(100% + (100vw - ((100% - 72px) / 4 * 8) - 100% - 192px) / 2);
        background-position: 31.8vw;
        @supports (-webkit-touch-callout: none) {
          background-position: 50%;
          background-attachment: scroll;
        }

        [dir='rtl'] & {
          right: 0;
          left: auto;
          background-position: -31.8vw;
        }

        img {
          position: fixed;
        }
      }
    }

    &.reverse {

      .plp-heading {
        grid-column: 6/span 7;
      }

      .products-list {
        grid-column: 6/span 7;

        .product-element {

          img {

          }
        }
      }


      .aside-container {
        grid-column: 1/span 4;

        .aside-img {
          position: absolute;
          height: 100%;
          right: 0;
          left: auto;
          background-position: -31.8vw;

          [dir='rtl'] & {
            right: auto;
            left: 0;
            background-position: 31.8vw;
          }

          img {
            position: fixed;
          }
        }
      }
    }
  }
}
