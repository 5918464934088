header {

  .menu-component {
    & > collapse-component {
      top: 48px;
    }

    .upper-bar {
      height: 48px;
      padding: 0 spacer(3);

      .left-side {
        width: 100%;
        
        .logo {
          img {
            height: 74px;
            margin-right: 0;

            [dir='rtl'] & {
              margin-left: 0;
            }
          }
        }

        .birthday-logo {
          z-index: 2;
          display: flex; 
          justify-content: center;
          align-items: center; 
          height: 100%;
          width: 100%;

          img {
            width: auto;
            margin-left: 17px;

            [dir='rtl'] & {
              margin-left: 17px;
              margin-right: -17px;
            }
          }
        }

        .brand-expression {
          display: none;
        }
      }

      .right-side {
        .e-commerce, .store-locator, .search {
          svg {
            width: 12px;
            height: 12px;
          }
        }

        .menu-button {
          width: 28px;
          padding-right: 0;

          [dir='rtl'] & {
            padding-left: 0;
          }

          span {
            display: none;
          }
        }

        .modal[target="search"] {
          top: spacer(6);
          right: 0;
        }

        .search-labels {
          grid-column: span 2;
          display: none;
        }

        .search-product-categories-container {
          display: none;
        }
      }
    }

    .main-menu {
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;

      .left-nav {
        order: 1;
        max-width: 100%;
        min-width: auto;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        nav {
          display: none;
        }

        .social-contacts-links {
          height: 56px;
          padding-right: 24px;
          gap: 10px;

          [dir='rtl'] & {
            padding-right: 0;
            padding-left: 24px;
          }

          li {
            margin: 0 spacer(1);

            &:first-child {
              margin-left: spacer(3);

              [dir='rtl'] & {
                margin-right: spacer(3);
                margin-left: spacer(1);
              }
            }

            &:last-child {
              //display: none;
            }
          }

          .contacts-link {
            align-items: center;

            &:after {
              @include sprite(envelope);
              content: '';
              height: 24px;
              width: 24px;
              margin-left: spacer(1);
              position: relative;

              [dir='rtl'] & {
                margin-right: spacer(1);
                margin-left: 0;
              }
            }
          }
        }
      }

      .right-nav {
        order: 0;
        padding: 0;
        position: relative;
        margin-bottom: spacer(7);

        [dir='rtl'] & {
          padding: 0;
        }

        & > collapse-component {
          display: block !important;
        }

        .localize {
          padding: 0;
          margin: 0 24px;
          border-bottom: 1px solid border-color(break);
          z-index: 2;
          float: none;

          [dir='rtl'] & {
            float: none;
          }

          button {

            &:after {

            }
          }
        }

        .products-categories-block {
          margin: 0;
          height: auto;

          [dir='rtl'] & {
            margin: 0;
          }

          .products-categories {
            margin-right: 0;
            margin-left: 0;

            .product-category-link {
              min-width: auto;
              width: 33%;
              margin: 0 0 spacer(2);

              .product-category {
                max-height: 100%;

                span {
                  font-size: rem(12);
                  min-width: 0;
                  padding: 0 8px;
                }
              }
            }
          }

          .goto {
            justify-content: center;
            margin: 0 spacer(4);
          }
        }

        .editorial-submenu {
          grid-template-columns: 1fr;
          background-color: bg-color(secondary);

          .product-category-link {
            .product-category {

              img {
                height: 280px;
                width: 100vw;
                vertical-align: middle;
              }

              span {
                padding: 0 spacer(3);

                &:after {
                  margin-top: spacer(2);
                }
              }
            }
          }

          .menu-links {
            font-size: rem(12);
            padding-left: spacer(3);
            display: block;

            [dir='rtl'] & {
              padding-right: spacer(3);
              padding-left: 0;
            }

            li {
              display: block;
            }
          }
        }
      }
    }
  }

  .language-modal {
    right: 0;
    left: 0;
    min-width: auto;
    transform: none;
    margin: 0 spacer(4);

    .language {
      grid-column: span 2;
    }
  }

  &.shrinked {
    .menu-component {
      & > collapse-component {
        top: 48px;
      }

      .main-menu {
        height: 100%;
      }

      .upper-bar {
        height: 48px;

        .left-side {
          .logo {
            img {

            }
          }
        }

        .right-side {
          .modal[target="search"] {
            top: spacer(6);
          }
        }
      }
    }
  }
}
