.c-paragraph {

  .paragraph-container {
    grid-column: span 12;
    margin: 0 24px;

    div {
      float: none;
      margin: spacer(-4) 0 0;
      width: 100%;
      padding: 0 32px;

      [dir='rtl'] & {
        margin: spacer(-4) 0 0;
      }

      picture {
        width: 100%;

        img {
          display: block;
          margin: 0 auto;
          float: none;
        }

        &:after {
          content: none;
        }
      }

      a {
        margin: 0 auto;
        justify-content: center;

        [dir='rtl'] & {
          margin: 0 auto;
        }
      }
    }

    h2, h3, h4 {
      font-weight: 500;
    }

    p {
      line-height: 32px;
    }

    ul, ol {
      font-size: rem(16);
      line-height: 32px;
    }
  }
}