.productcard-carousel {

  .carousel-images-list {
    flex-wrap: nowrap;

    .images-list-item {
      justify-content: center;
      margin: 0;

      &:first-child:not(.image-lateral-first) {
        margin-left: 50%;

        [dir='rtl'] & {
          margin-right: 50%;
          margin-left: 0;
        }
      }

      &:last-child:not(.image-lateral-last) {
        margin-right: 50%;

        [dir='rtl'] & {
          margin-right: 0;
          margin-left: 50%;
        }
      }

      img {
        width: auto;
        max-height: 153px;
      }

      &.image-lateral-first {
        position: static;

        img {
        }
      }

      &.image-lateral-last {
        position: static;
        left: auto;
      }
    }
  }

  .product-title {


    span {

    }
  }

  .prev, .next {
    display: none;
  }

  .prev {

  }

  .bullet-div {


    .bullet {
      width: 8px;
      height: 8px;

      &:not(:last-child) {
        margin-right: spacer(1);
      }

      &:focus, &:hover {
        & > span {

        }
      }

      span {


        &:before {

        }
      }
    }
  }
}