nav-component {
  &.sticky {
    top: 48px;
    overflow: hidden;
  }

  .scrolling-nav {
    //text-align: left;
    .prev, .next {
      visibility: hidden;
    }

    ul {
      .filter {
        &:not(.active) {
          &:hover {
            &:before {
              content: none;
            }
          }
        }

        &.active {
          &.btn {
            &:after {
              .filters-modal & {
                @include sprite(check, (color:text-color(brand)));
                height: 24px;
                width: 24px;
              }
            }
          }
        }
      }
    }
  }
}