// _u_utilname

.text-center {
  text-align: center !important;
}

a {
  &.icons {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: rem(12);
    font-weight: bold;
    letter-spacing: 1px;
    line-height: rem(16);

    &.icons-chevron-link {
      &:before {
        @include sprite(chevron-link);
        content: "";
        width: 24px;
        height: 24px;
        display: block;
        margin-right: spacer(1);

        [dir='rtl'] & {
          margin-right: 0;
          margin-left: spacer(1);
          transform: scale(-1);
        }
      }
    }
  }
}

ul {
  &.list {
    padding: 0;
    margin: 0;
  }
}

li {
  &.ds-block {
    display: block;
  }

  &.ds-inline-block {
    display: inline-block;
  }
}

.bg-primary {
  background-color: bg-color(reverse);
}

.bg-secondary {
  background-color: bg-color(secondary);
}

button {
  cursor: pointer;
}

.hide-sm {
  display: none;
}

@media (min-width: 768px) {
  .hide-sm {
    display: block;
  }
}

.show-sm {
  display: none;
}

@media (max-width: 767px) {
  .show-sm {
    display: block;
  }
}