.breadcrumb {
  grid-column: 1/13;
  margin-bottom: spacer(7);

  ol {
    list-style: none;
    padding: 0;
    margin: 0;

    .page-header & {
      max-width: 1128px;
      margin: 16px auto;
      padding: 56px 0 0 0;
    }

    li {
      display: inline-block;
    }

    a {
      font-weight: 500;
      position: relative;
      font-size: rem(12);
      letter-spacing: 0.5px;
      line-height: 16px;
      font-family: font-family(primary);
      color: text-color(secondary);

      &.current {
        //
      }

      &:not(.current) {
        padding-right: spacer(2);

        [dir='rtl'] & {
          padding-right: 0;
          padding-left: spacer(2);
        }

        &:after {
          content: "";
          @include sprite(chevron-right);
          display: inline-block;
          position: absolute;
          width: 8px;
          height: 8px;
          right: 4px;
          top: 50%;
          transform: translateY(-50%);

          [dir='rtl'] & {
            right: auto;
            left: 4px;
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }
  }
}