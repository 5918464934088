.chatbot-button {
  right: spacer(1);
  bottom: spacer(2);

  [dir='rtl'] & {
    right: auto;
    left: spacer(1);
  }

  .t-loacker-product-detail & {
    bottom: spacer(9);
  }

  &:after {

  }
}

.c-chatbot {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: auto;
  overflow-y: auto;
  border-radius: 0;

  [dir='rtl'] & {
    right: 0;
    left: 0;
  }

  .chatbot-upper-block {
    border-radius: 0;

    &:after {

    }

    .chatbot-title {

    }
  }

  .chatbot-main-block {
    padding-bottom: spacer(3);
    border-radius: 0;

    &:before {
      border-right-width: 50vw;
      border-left-width: 50vw;
    }

    .chatbot-link {

      &:after {

      }

      .chatbot-par {


      }
    }
  }
}