.c-banner-launch {
  padding: 104px spacer(3);

  .banner-launch-container {
    grid-template-columns: auto;
    grid-row-gap: 56px;

    &.reverse {
      img {
        grid-column: 1;
      }
    }

    img {
      grid-column: 1;
      grid-row: 2;
      padding: 0;
    }
  }
}