footer {
  .footer-link-list {
    display: block;

    li {
      display: block;
      margin-bottom: spacer(3);
      font-size: rem(12);
    }
  }

  .bottom-area {
    ul {
      flex-flow: column;

      li {
        margin: spacer(1) 0;

        &:not(:last-child) {
          &:after {
            content: none;
          }
        }
      }
    }

    .brand-expression {
    }
  }
}

.birthday-footer {
  background-image: url("/docroot/loacker/dist/images/mobile/footer.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  background-color: bg-color(reverse);
  color: text-color(reverse);

  .bithday-footer-wrapper {
    margin-top: 2rem;
    footer .bottom-area:before,
    .bottom-area:before {
      border: none;
    }

    footer .bottom-area,
    .bottom-area {
      background-image: none;
    }

    .info {
      margin-top: unset;
    }

    footer .footer-link-list,
    .footer-link-list {
      display: flex;
      font-weight: 500;
      margin-top: 0px;
    }

    .footer-p {
      font-weight: 400;
      font-size: 14px;
      line-height: 31px;
      color: text-color(reverse);
    }
    .birthday-wrapper {
      display: block;
    }

    .birthday-cake-wrapper {
      position: unset;
      left: unset;
      transform: unset;
    }

    .social-contacts-links-wrapper {
      margin-left: unset;
      margin-right: unset;
    }

    .footer-link-list {
      margin-top: 0rem;
    }
  }
}
