.c-store-info {
  padding: spacer(10) spacer(3);

  .store-info-container {
    grid-template-columns: auto;
    grid-row-gap: 32px;

    .store-description {
      grid-column: auto;
    }

    .store-info {
      grid-column: auto;

      &:before {
        content: none;
      }
    }
  }
}