.birthday-img-container {
  .birthday-cake{
    position: fixed;
    bottom: 0%;
    left: 5%;
    z-index: 10;
    cursor: pointer;
  }

  .modal {
    display: none; 
    position: fixed;
    z-index: 20;
    bottom: 0%;
    left: 0%;
  }

  .modal-content {
    color: text-color(reverse);
    // margin: 15% auto;
    padding: 14px 23px;
    // width: 70%;
    // height: 40vh;
    text-align: center;
    border-radius: 10px;
  }

  .birthday-modal-container {
    display: flex;
    background-image: url("/docroot/loacker/dist/images/modal-desktop.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 500px;
    color: text-color(reverse);
    // margin: 15% auto;
    padding: 14px 23px;
    height: 50%;
    text-align: center;
    border-radius: 10px;
    position: relative;

  }

  .birthday-modal-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .birthday-modal-text {
    flex: 1;
    display: block;
    justify-content: center;
    align-items: center;
    background-color: bg-color(reverse);
    border-radius: 10px;
    margin: 7px 14px;
    margin-bottom: 14px;
    padding: 11px 20px;

    p {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 2%;
      color: text-color(secondary);
    }

    .discover-more {
      font-size: 11px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      text-transform: uppercase;
      .discover-more-image {
          width: 19px;
          height: 20px;
          vertical-align: text-bottom;
          margin-right: 8px;
      }
  }
  }

  .close {
    cursor: pointer;
    color: text-color(primary);
    float: right;
    font-size: 28px;
    position: absolute;
    left: 100%;
    top: 0%;
  }

  .close:hover,
  .close:focus {
    color: text-color(primary);;
  }

  .modal-left-element {
    position: absolute;
    right: 83%;
    top: 70%;

    img {
      width: 85px;
    }
  }

  .modal-right-element {
    position: absolute;
    left: 88%;
    top: 68%;

    img {
      width: 65px;
    }
  }
}
