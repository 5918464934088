.c-related-news {
  padding: spacer(13) 0;
  overflow-x: hidden;

  .related-news-container {
    margin: 0 auto;
    max-width: 1176px;
    padding: 0 spacer(3);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;

    div {
      grid-column: 1/6;
      position: relative;
      margin-top: 0;

      h2 {
        &:before {
          content: "";
          width: 72px;
          height: 2px;
          background-color: bg-color(brand);
          position: absolute;
          top: 0;
          left: 0;

          [dir='rtl'] & {
            right: 0;
            left: auto;
          }
        }
      }
    }

    nav-component {
      grid-column: 6/13;
      position: static;
      width: calc(50vw + 14%);
      margin-right: calc((-100vw + 100% + 100% / 7 * 5) / 2); //NOTE HOLY GRAIL
      border-bottom: none;

      [dir='rtl'] & {
        margin-right: 0;
        margin-left: calc((-100vw + 100% + 100% / 7 * 5) / 2);
      }

      .scrolling-nav {
        height: 100%;

        .prev {
          height: auto;
          bottom: 0;
          top: auto;
          background: none;
          box-shadow: none;

          [dir='rtl'] & {
            left: 0;
          }
        }

        .next {
          height: auto;
          top: auto;
          bottom: 0;
          left: 0;
          margin-left: 50px;
          background: none;
          box-shadow: none;

          [dir='rtl'] & {
            right: 0;
            left: auto;
            margin-right: 50px;
            margin-left: 0;
          }
        }

        ul {
          height: 100%;

          li {
            display: inline-block;
            vertical-align: top;

            figure {
              max-width: 265px;
              margin: 0 spacer(3) 0 0;

              [dir='rtl'] & {
                margin: 0 0 0 spacer(3);
              }

              picture {
                img {
                  width: 100%;
                  height: 200px;
                  object-fit: cover;
                  border-radius: 4px;
                }
              }

              figcaption {
                white-space: normal;
                text-align: left;

                [dir='rtl'] & {
                  text-align: right;
                }

                p {
                  line-height: rem(24);
                }
              }
            }
          }
        }
      }
    }
  }
}