search-component {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 24px;
  max-width: 1128px;
  margin: spacer(10) auto;

  .search-form {
    grid-column: 2/12;
    margin: 0 spacer(5) spacer(7);

    .search-input-wrapper {
      position: relative;

      &:before {
        @include sprite(search, (color:text-color(placeholder)));
        content: "";
        display: block;
        height: 24px;
        width: 24px;
        position: absolute;
        margin: spacer(2);
      }

      &:after {
        content: "";
        height: calc(100% - 16px);
        position: absolute;
        left: spacer(7);
        width: 2px;
        border-right: 1px solid border-color(break);
        margin: spacer(1) 0;

        [dir='rtl'] & {
          right: spacer(7);
          left: auto;
        }
      }

      .search-form-input {
        width: 100%;
        height: 57px;
        padding-left: spacer(8);
        border: 1px solid border-color(break);
        border-radius: 4px;

        [dir='rtl'] & {
          padding-right: spacer(8);
          padding-left: 0;
        }

        &::placeholder {
          color: text-color(placeholder);
          text-transform: uppercase;
          font-size: rem(14);
          font-weight: bold;
          letter-spacing: 0.2px;
          line-height: 16px;
        }
      }

      .input-reset-btn {
        display: none;
        width: 48px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -56px;
        font-size: rem(30);
        border: none;
        color: text-color(brand);
        background: none;

        [dir='rtl'] & {
          right: auto;
          left: -56px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .search-listing {
    grid-column: 3/11;
    margin: 0 spacer(-3);

    .search-group-item {
      margin-bottom: spacer(7);

      .list-group-title {
        color: text-color(placeholder);
        font-weight: 500;
        letter-spacing: 0;
        line-height: rem(32);
        padding: 0 spacer(3) spacer(1);
        border-bottom: 1px solid border-color(break);
        margin-top: 0;
        margin-bottom: spacer(2);
      }

      .search-sublist {
        padding: 0 24px;

        .search-item-link {
          display: grid;
          grid-template-columns: 96px 1fr;
          grid-column-gap: 24px;
          align-items: center;
          min-height: 72px;
          margin-bottom: spacer(1);

          .sublist-item-img {
            object-fit: cover;
            max-width: 96px;
            height: 100%;
            max-height: 72px;
            align-self: flex-start;
            display: inline-block;
          }

          .sublist-item-text {
            display: inline-block;
            color: text-color(primary);
            margin: 0;
          }
        }
      }
    }
  }

  .icons-chevron-link {
    &:before {
      [dir='rtl'] & {
        margin-right: 0;
        margin-left: spacer(1);
        transform: scale(-1);
      }
    }
  }
}