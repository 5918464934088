.c-download-list {
  position: relative;
  width: 100%;
  padding: spacer(10) 0;
  background-color: bg-color(secondary);
  margin: spacer(10) 0;

  .download-list-container {
    max-width: 744px;
    margin: 0 auto;

    h2 {
      margin-top: 0;
      margin-bottom: 16px;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        a {
          color: text-color(brand);
          font-family: font-family(primary);
          font-size: 18px;
          font-weight: 500;
          line-height: 32px;
        }
      }
    }
  }
}