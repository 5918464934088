.c-video {
  position: relative;
  overflow: hidden;
  margin: spacer(10) 0 0;

  video-component {
    margin: 0 auto;
    max-width: 1128px;
    display: block;
    position: relative;

    video {
      vertical-align: middle;
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0), bg-color(primary));
    }

    .video-container {
      position: absolute;
      bottom: spacer(10);
      max-width: 50%;
      padding-left: spacer(3);

      .header2 {
        color: text-color(reverse);
      }

      p {
        color: text-color(reverse);
      }

      .buttons {
        margin-top: 44px;

        .button-secondary {
          margin-right: spacer(3);
        }
      }
    }

    .player-button {
      @include sprite(pause);
      display: block;
      height: 20px;
      width: 20px;
      position: absolute;
      top: spacer(2);

      &.paused {
        @include sprite(play);
      }
    }
  }

  .video-collapse {
    display: flex;
    align-items: center;

    .video-modal {
      width: 100vw;
      position: relative;
      height: 100vh;

      button {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(-50%);
      }

      iframe {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 50px);
      }

      .cookieconsent-optout-marketing {
        a {
          color: text-color(brand);
          text-decoration: underline;
        }

        .paragraph {
          color: text-color(reverse);
          padding: 0 spacer(3);
        }

        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &:after {
    content: "";
    background: white;
    position: absolute;
    bottom: 0;
    height: 80px;
    left: 0;
    right: 0;
  }

  & + section {
    margin-top: 0;

    div {
      margin-top: 0;
    }
  }
}

section.c-video:last-of-type {
  padding-bottom: 56px;
  margin-bottom: 0;

  &:after {
    height: 56px;
  }
}

