.c-taste-list {
  //padding: 40px 24px;
  padding: 0 24px;
  margin: spacer(5) auto;
  grid-template-columns: auto;

  .taste-list-container {
    grid-column: auto;

    .header2 {
      margin-top: 26px;
      &:before {
        //bottom: 56px;
        top: spacer(-3);
      }
    }

    p {
      margin-bottom: 80px;
    }

    .c-accordion-container {
      .accordion-trigger {
        grid-column: span 12;
      }

      collapse-component {
        &.collapsed {
          .accordion-body {
            margin-left: 0;
          }
        }
      }
    }
  }
}