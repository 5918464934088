parallax-component {
  height: calc(50vh - 48px);

  h1 {
    font-size: rem(36);
    line-height: rem(36);
    padding: 0 spacer(3);
    top: spacer(9);
  }

  div:not(.fade) {
    height: 50vh;
  }

}