.c-cookie-policy {
  div:first-child {
    padding: 0 spacer(3);
    margin: spacer(6) auto;
    max-width: 798px;
  }
}

// COMMENTED for LOAC-396
/* THIS RULES ARE FOR THE COOKIEBOT BANNER (sent by Timoty) */
/*

----- PHASE 2 -----
#CybotCookiebotDialogBody {
  max-width: none !important;
}

#CybotCookiebotDialogPoweredbyCybot {
  display: none !important;
}

#CybotCookiebotDialogDetailBody {
  width: 97% !important;
  max-width: none !important;
}

#CybotCookiebotDialogBodyContent {
  padding-left: 8px !important;
}

#CybotCookiebotDialogBodyLevelButtons {
  margin-left: 8px !important;
}

----- PHASE 1 -----
#CybotCookiebotDialogPoweredbyLink {
  display: none !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  background-color: white !important;
  color: #b1b1b1 !important;
  border: 1px solid #B1B1B1 !important;
}*/