#ca-facts {
  margin: 0 auto;
  background: #fff;
  border: 1px solid #000;
  padding: 8px 0 4px;
  font-size: 15px;
  line-height: 15px;
  box-shadow: 4px 10px 10px rgba(0, 0, 0, 0.2);
  width: 260px;
  font-family: font-family(primary);
  text-align: left;
  color: #000000;

  .facts_title {
    font-size: rem(22);
    line-height: rem(20);
    color: black;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 4px;
    font-weight: 600;
  }

  .facts_serving_pre {
    padding-bottom: 3px;
    font-size: 14px;
    line-height: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .facts_serving_size {
    font-size: 16px;
    line-height: 14px;
    padding: 2px 5px;
  }

  .facts_box {
    border-top: 13px solid #231f20;
    margin-left: 5px;
    margin-right: 5px;

    &.thin {
      border-width: 5px;
    }

    &.extra-thin {
      border-width: 2px;
    }

    &.no-border {
      border: none;
    }

    .fact_row {
      padding-bottom: 3px;
      padding-top: 3px;
      font-size: 14px;
      line-height: 14px;
      border-bottom: 1px solid #838181;
      position: relative;

      &:last-child {
        border-bottom: none;
      }

      &.txt_right {
        text-align: right;
      }

      &.sub {
        padding-top: 0;
        padding-bottom: 2px;
        padding-left: 11px;
      }

      &.sublv2 {
        padding-left: 36px;
      }

      &.sublv3 {
        margin-left: 34px;
      }

      &.no-border {
        border: none;
      }

      div {
        float: right;
      }

      &.calories {
        border-bottom: none;

        .calories-label {
          border-bottom: 5px solid black;
          line-height: rem(24);
          font-size: rem(18);
          display: inline-block;
        }

        .calories-percentage-label {
          font-size: rem(10);
          line-height: rem(16);
          text-align: right;
          position: absolute;
          bottom: 0;
          right: 0;
          padding-bottom: 5px;

          small {
            font-size: 10px;
            letter-spacing: 0.2px;
            font-weight: 500;
          }
        }
      }
      &.fats-box {
        div {
          display: flex;
          align-items: center;
          height: 30px;
        }
        span {
          display: block;
          &:first-of-type {
            padding-bottom: 2px;
          }
        }
      }
    }

    .fact_row_norule {
      padding-bottom: 2px;
      padding-top: 5px;
      font-size: 14px;
      line-height: 14px;
      position: relative;
    }
  }

  .facts_descr {
    margin: 0 5px;
    position: relative;
    color: #000;
    border-top: 5px solid black;

    p {
      color: black;
      font-size: rem(11);
      line-height: rem(12);
      margin: 2px 0;
      letter-spacing: -0.4px;
    }
  }

  strong {
    color: #000;
  }
}