.modal-collapse {
  .plus-icon {
    font-size: 60px;
    padding: spacer(0.5) 0;
    display: flex;
    &:after {
      @include sprite(more);
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
    }
  }
}