.c-nutritional-facts {
  padding-right: spacer(3);
  padding-left: spacer(3);

  .nutritional-facts-container {
    .facts-list {
      overflow-x: scroll;
      justify-content: flex-start;
    }

    .collapse-line {
      background-image: none;

      .transparent-collapse-btn {
        min-width: auto;
        width: 100%;
      }
    }

    .nutritional-section {

      .table-container {
        display: flex;
        flex-direction: column;
      }

      .facts-description-text {
        //order: 1;
      }

      table {
        text-align: left;

        tbody {
          display: flex;
        }

        tr {
          width: 50%;
          display: grid;
          grid-auto-rows: 1fr;

          th:nth-child(odd), td:nth-child(odd) {
            background-color: #ffffff10;
          }

          &:first-of-type {
            background-color: transparent;

            th {
              display: block;
              border-top: 1px solid #ffffff60;
              border-bottom: 1px solid #ffffff60;
              height: auto;
            }
          }

          td {
            display: block;
            border-top: 1px solid #ffffff60;
            border-bottom: 1px solid #ffffff60;
          }
        }
      }
    }
  }
}