.chatbot-button {
  display: block;
  position: fixed;
  right: 38px;
  bottom: 44px;
  height: 50px;
  width: 50px;
  z-index: 2;
  padding: 0;

  [dir='rtl'] & {
    right: auto;
    left: 38px;
  }

  &:after {
    @include sprite('chatbot');
    content: "";
    display: block;
    height: 50px;
    width: 50px;
    position: relative;
  }
}

.c-chatbot {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 32px;
  bottom: 42px;
  width: 375px;
  min-height: 552px;
  z-index: 11;
  background-color: bg-color(reverse);
  border-radius: 20px;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.3);

  [dir='rtl'] & {
    right: auto;
    left: 32px;
  }

  .chatbot-upper-block {
    text-align: right;
    background-color: bg-color(brand);
    position: relative;
    min-height: 150px;
    padding-top: spacer(1);
    border-radius: 20px 20px 0 0;

    .chatbot-title {
      color: text-color(reverse);
      text-align: center;
      padding: 0 spacer(3);
      margin-top: 0;
    }
  }

  .chatbot-main-block {
    background-image: linear-gradient(bg-color(brand), bg-color(reverse) 1px);
    position: relative;
    padding: spacer(5) spacer(3) spacer(7);
    z-index: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    border-radius: 20px;

    &:before {
      content: "";
      position: absolute;
      display: block;
      z-index: -1;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: bg-color(brand) transparent transparent transparent;
      border-right-width: 187.5px;
      border-left-width: 187.5px;
      border-top-width: 15px;
    }

    .chatbot-link {
      padding-right: 46px;
      padding-left: spacer(2);
      font-weight: 400;
      font-size: rem(14);
      line-height: rem(24);
      margin-bottom: spacer(1);
      border-radius: 24px;

      [dir='rtl'] & {
        padding-right: spacer(2);
        padding-left: 46px;
      }

      &:after {
        @include sprite(accordion, (color:text-color(reverse)));
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        transform: rotate(-90deg);
        margin-left: spacer(1);
        position: absolute;

        [dir='rtl'] & {
          transform: rotate(90deg);
          margin-right: spacer(1);
          margin-left: 0;
        }
      }

      .chatbot-par {
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(24);
        color: text-color(reverse);

      }
    }
  }
}