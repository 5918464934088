.c-scrolling-banner {
  background-image: url("/docroot/loacker/dist/images/mobile/pure-goodness-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  

  .c-scrolling-banner-bg-element {
    display: none;
  }
  .scrolling-banner-container {
    padding: 0 spacer(3);
    grid-row-gap: 56px;
    margin: 82px auto;

    .scrolling-banner-heading {
      grid-column: span 12;
      position: relative;
      top: 0;
    }

    .list {
      grid-column: span 12;
      grid-row-gap: 56px;
    }

    .scrolling-banner-card {
      display: block;

      .header3 {
        margin-bottom: 8px;
      }

      img {
        max-height: 248px;
        object-fit: cover;
      }
    }
  }
}