.productcard-carousel {
  position: relative;
  margin: spacer(10) 0;
  z-index: 1;

  .productcard-carousel-container {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  .product-title {
    margin: 0;

    span {
      color: text-color(brand);
      font-family: font-family(primary);
      font-size: rem(24);
      letter-spacing: 0;
      line-height: 32px;
      text-transform: uppercase;
      display: block;
    }
  }

  .carousel-titles-list {
    white-space: nowrap;
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;


    li {
      &.carousel-title {
        white-space: normal;
      }

      &.side-carousel-title-li {
        position: absolute;

        &:first-child {
          right: 100%;
          margin: 0 spacer(3) 0 0;

          [dir='rtl'] & {
            right: auto;
            left: 100%;
            margin: 0 0 0 spacer(3);
          }
        }

        &:last-child {
          left: 100%;
          margin: 0 0 0 spacer(3);

          [dir='rtl'] & {
            right: 100%;
            left: auto;
            margin: 0 spacer(3) 0 0;
          }
        }
      }

      .side-carousel-title {
        color: text-color(secondaryLighter);
      }
    }
  }

  .carousel-images-list {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 512px;
    position: relative;

    .images-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      margin: 0 spacer(2.5);

      img {
        height: 100%;
        max-height: 384px;
        vertical-align: middle;
      }

      &.image-lateral-first {
        position: absolute;
        right: 100%;

        [dir='rtl'] & {
          right: auto;
          left: 100%;
        }
      }

      &.image-lateral-last {
        position: absolute;
        left: 100%;

        [dir='rtl'] & {
          right: 100%;
          left: auto;
        }
      }
    }
  }

  .prev, .next {
    content: "";
    @include sprite(chevron-right);
    width: 24px;
    height: 24px;
  }

  .prev {
    transform: rotate(180deg);

    [dir='rtl'] & {
      transform: none;
    }
  }

  .next {
    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }

  .bullet-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;

    .bullet {
      width: 10px;
      height: 10px;
      background-color: bg-color(primary);
      border-radius: 50%;
      position: relative;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: spacer(2);

        [dir='rtl'] & {
          margin-right: 0;
          margin-left: spacer(2);
        }
      }

      &:focus, &:hover {
        & > span {
          display: initial;
        }
      }

      span {
        display: none;
        position: absolute;
        font-family: font-family(primary);
        font-weight: bold;
        text-transform: uppercase;
        background-color: rgba(0, 0, 0, 0.6);
        color: text-color(reverse);
        top: 20px;
        width: 116px;
        height: auto;
        text-align: center;
        line-height: 32px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4px;
        z-index: 1;

        &:before {
          content: "";
          border: 10px solid rgba(0, 0, 0, 0.6);;
          border-top-color: transparent;
          border-left-color: transparent;
          border-right-color: transparent;
          height: 51px;
          width: 20px;
          position: absolute;
          top: -51px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
