parallax-component {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  //overflow: hidden;
  z-index: -2;
  max-width: 100%;
  //transition: opacity .3s ease-in;
  /*
    &:before {
      content: "";
      position: absolute;
      top: -10000px;
      height: 10000px;
      width: 100%;
      background-color: bg-color(reverse);
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -10000px;
      height: 10000px;
      width: 100%;
      background-color: bg-color(reverse);
    }
  */

  &:after {
    content: "";
    position: absolute;
    //bottom: 0;
    top: 100%;
    left: 0;
    right: 0;
    height: spacer(16);
    background: bg-color(reverse);
  }

  h1 {
    top: spacer(23);
    transform: translateY(-50%);
    position: fixed;
    font-size: rem(80);
    line-height: rem(80);
    text-align: center;
    font-weight: 500;
    letter-spacing: -1.5px;
  }

  [data-background] {
    top: 0;
  }

  div:not(.fade) {
    //position: absolute;

    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    max-width: 100%;
    //object-fit: cover;
    img {
      width: 100vw;
      height: 100%;
      object-fit: cover;
      object-position: bottom;
      max-width: 100%;
    }

    &.cloud-base {
      bottom: -17px;
      left: 0;
      position: absolute;
      z-index: 99;
      //width: 105%;
    }
  }

  .absolute {
    position: fixed;
    width: 100%;
    height: 100vh;
    //display: none;
    overflow: hidden;

    .immagine {
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);

      .interno {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }

    img {
      width: 100%;
      height: auto;
      position: relative;
    }
  }

  .fade {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    background: white;
    transition: 3s;

    &.in {

    }

    &.out {
      opacity: 1;
    }
  }
}

body > *:not(header, footer, slider-componentcst) {
  background-color: bg-color(reverse);
}

body {
  animation-name: fadein;
  animation-duration: .5s;
  position: relative;

}

.t-loacker-hub, .t-loacker-internal {
  section {
    &:after {
      content: "";
      position: absolute;
      bottom: -128px;
      height: 128px;
      right: 0;
      left: 0;
      background: inherit;
    }
  }
}

.bg-hider-top {
  position: absolute;
  top: 0;
  background: bg-color(reverse);
  left: 0;
  right: 0;
}

.bg-hider-bottom {
  position: absolute;
  bottom: 0;
  background: bg-color(reverse);
  left: 0;
  right: 0;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  79% {
    opacity: 0;
  }
  80% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}


@keyframes opaque {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}