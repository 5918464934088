.c-tab-products {
  background-image: url("/docroot/loacker/dist/images/mobile/most-wanted-products.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;

  .c-tab-products-bg-element {
    display:none;
  } 

  .c-tab-products-container {
    padding: 0 spacer(3);

    .block {
      grid-template-rows: auto;
    }

    .tab-selector {
      padding: 0;
      overflow-x: hidden;

      [dir='rtl'] & {
        padding: 0;
      }

      ul {
        white-space: nowrap;
        overflow-x: scroll;
      }
    }

    .tab-products-heading {
      padding: 0;

      [dir='rtl'] & {
        padding: 0;
      }
    }

    .tab-image {
      grid-row: 2/4;
      grid-column: 1/13;

      picture {
        grid-row: 1/3;

        img {
          height: 216px;
          width: 100%;
          margin-top: 0;
        }
      }
    }

    .products-list {
      margin-top: -100px;

      .product-element {
        grid-column: auto/span 6;

        .special-attr {
          width: 40px;
          height: 40px;
          right: 24px;
          top: 24px;
        }
      }
    }
  }
}