countdown-component {
    .countdown-wrapper ol {
      text-align: left;
      li {
        font-size: 1.4rem;
        padding: 0.5rem;
        text-align: center;
        span {
          font-size: 1.4rem;
        }
      }
    }
  }
  
  