
.c-square-list-1 {
  .square-listing {

    li.ds-block:nth-of-type(7n+1):nth-last-of-type(7n+0), li.ds-block:nth-of-type(7n+1):nth-last-of-type(7n+0) ~ li {
      flex-basis: 25%
    }

    li.ds-block:nth-of-type(8n+1):nth-last-of-type(8n+0), li.ds-block:nth-of-type(8n+1):nth-last-of-type(8n+0) ~ li {
      flex-basis: 25%
    }

    li.ds-block:nth-of-type(9n+1):nth-last-of-type(9n+0), li.ds-block:nth-of-type(9n+1):nth-last-of-type(9n+0) ~ li {
      flex-basis: 20%
    }

    li.ds-block:nth-of-type(10n+1):nth-last-of-type(10n+0), li.ds-block:nth-of-type(10n+1):nth-last-of-type(10n+0) ~ li {
      flex-basis: 20%
    }
    li.ds-block:nth-of-type(13n+1):nth-last-of-type(13n+0), li.ds-block:nth-of-type(13n+1):nth-last-of-type(13n+0) ~ li {
      flex-basis: 20%
    }
  }

}

.c-square-list {

  .square-listing {

    li.ds-block:nth-of-type(7n+1):nth-last-of-type(7n+0), li.ds-block:nth-of-type(7n+1):nth-last-of-type(7n+0) ~ li {
      flex-basis: 25%
    }

    li.ds-block:nth-of-type(8n+1):nth-last-of-type(8n+0), li.ds-block:nth-of-type(8n+1):nth-last-of-type(8n+0) ~ li {
      flex-basis: 25%
    }

    li.ds-block:nth-of-type(9n+1):nth-last-of-type(9n+0), li.ds-block:nth-of-type(9n+1):nth-last-of-type(9n+0) ~ li {
      flex-basis: 20%
    }

    li.ds-block:nth-of-type(10n+1):nth-last-of-type(10n+0), li.ds-block:nth-of-type(10n+1):nth-last-of-type(10n+0) ~ li {
      flex-basis: 20%
    }
    li.ds-block:nth-of-type(13n+1):nth-last-of-type(13n+0), li.ds-block:nth-of-type(13n+1):nth-last-of-type(13n+0) ~ li {
      flex-basis: 20%
    }
  }

}