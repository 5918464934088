slider-component {
  //background-color: teal;
  border: 1px solid teal;
  height: 100%;
  display: block;
  //opacity: 0;
  //animation: fadein 2s;

  .container {
    height: 100%;
    width: auto;
    overflow: hidden;
    padding: 0;
    margin: 0;
    max-width: 100%;

    ul {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      //display: flex;
      white-space: nowrap;
      //transition: 1s linear;
      li {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;

        &:first-child {
          display: none;
        }
      }
    }

    .slide {
      display: inline-block;
      height: 50px;
      width: 100%;

      &.slide1 {
        background-color: lightcoral;
      }

      &.slide2 {
        background-color: lightgreen;
      }

      &.slide3 {
        background-color: lightskyblue;
      }
    }

  }
}