customvideo-component {
  position: relative;
  display: block;
  margin-bottom: 15px;
  padding-bottom: 0;
  width: 100%; 
  overflow: hidden;
  height: 90vh;

  iframe {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 400%; 
      height: 100%; 
      border: none;
  }

  .birthday-paint-background {
      position: absolute;
      width: 100%;
      top: 99%;
      z-index: 10;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; 
  }

  .player-button {
    position: absolute;
    bottom: 40px;
    right: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    z-index: 1;
  
    img {
      width: 36px !important;
      height: 36px !important;
      display: block;
    }
  }
}


