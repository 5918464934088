.c-nutritional-facts {
  background-color: bg-color(secondaryDarker);
  color: text-color(reverse);
  text-align: center;
  padding: 80px 0;

  p, h4 {
    color: text-color(reverse);
  }

  .nutritional-facts-container {
    .nutritional-facts-header {
      color: text-color(reverse);
      margin-bottom: spacer(9);
      letter-spacing: 0;
    }

    //display: grid;
    grid-template-columns: repeat(12, 1fr);
    max-width: 1128px;
    margin: 0 auto;
    column-gap: 24px;

    .facts-list {
      display: flex;
      justify-content: center;
      margin-bottom: spacer(8);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .collapse-line {
      display: block;
      background-image: linear-gradient(to bottom, transparent 50%, white calc(50% + 2px), transparent 50%);

      .transparent-collapse-btn {
        background: bg-color(secondaryDarker);
        color: text-color(reverse);
        border: 2px solid border-color(reverse);
        min-height: 64px;
        min-width: 327px;
      }
    }

    .nutritional-section {
      margin-top: spacer(5);

      .nutritional-section-header {
        color: text-color(reverse);
      }

      .tabs-list-selector {
        display: flex;
        justify-content: center;
        margin-bottom: spacer(10);

        .tabs-list-selector-button {
          &:not(:last-child) {
            margin-right: spacer(3);
          }

          button {
            color: text-color(reverse);
            font-weight: bold;
            min-height: 40px;
            padding: spacer(1);
          }

          .trigger-active {
            button {
              border-bottom: 4px solid border-color(reverse);
            }
          }
        }
      }

      .facts-description-text {
        margin-bottom: spacer(7);
        text-align: left;

        strong {
          color: text-color(reverse);
        }
      }

      .facts-table-title {
        font-size: rem(12);
        font-weight: bold;
        letter-spacing: rem(1);
        line-height: rem(16);
        text-transform: uppercase;
        text-align: left;
      }

      table {
        width: 100%;
        font-family: font-family(primary);
        border-collapse: collapse;
        margin-bottom: spacer(7);

        tr {
          border-top: 1px solid #ffffff60;
          border-bottom: 1px solid #ffffff60;

          &:first-of-type {
            background-color: #ffffff10;

            th {
              height: 64px;
              vertical-align: baseline;
              padding: 8px 14px 0;
            }
          }

          td {
            padding: 8px 0;
          }
        }
      }
    }
  }
}