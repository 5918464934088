.c-story-item {
  padding: 40px 0;

  .story-item-container {
    flex-direction: column;
    padding: 0 spacer(3);

    .year {
      display: none;
    }

    .story-image {
      max-width: 100%;
      width: 100%;
      margin: 0 auto spacer(3);
      object-fit: cover;
    }

    .story-text {
      max-width: 100%;

      p {
        font-size: 14px;
      }
    }
  }
}