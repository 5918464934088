.birthday-countdown-container {
    background-image: url("/docroot/loacker/dist/images/birthday-countdown-bg.png");

  

    .birthday-countdown-wrapper {
      countdown-component {
        top: 28%;
        scale: 1.3;   
        left: 20%;
      }
      
  }
  
      .birthday-text-wrapper {
            justify-content: center;
            margin-right: 5rem;
  
        .text-container {
          width: 60%;
  
          h1 {
            font-weight: 700;
            font-size: 57px;
            line-height: 70px;
            color: text-color(reverse);
            letter-spacing: -3%;
          }
  
          span {
            font-weight: 500;
            font-size: 57px;
            line-height: 70px;
            color: text-color(reverse);
          }
  
          p {
            font-weight: 700;
            font-size: 20px;
            line-height: 31px;
            color: text-color(reverse);
            margin: 2rem 0rem 2rem 0rem;
          }
  
          button {
            margin-top: 4rem;
            width: 45%;
  
            a {
              color: text-color(primary);
            }
          }
        }
      }
    }
  