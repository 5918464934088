.birthday-run-game-container {
    width: 40%;
    margin: 0 auto;
    text-align: center;
    background-color: bg-color(reverse);
    display: block;
    box-sizing: border-box;

    h2 {
        font-size: 57px;
        font-weight: 700;
        line-height: 72.96px;
        color: text-color(primary);
        margin: 0;

        .highlight {
            color: text-color(brand);
            font-size: 57px;
            font-weight: 700;
            line-height: 72.96px;
        }
    }

    p {
        font-size: 20px;
        font-weight: 700;
        line-height: 31px;
        color: text-color(primary);
        margin-top: 40px;
        margin-bottom: 22px;
    }

    button {
        padding: 17px 23px;
        border-radius: 4px;
        background-color: bg-color(brand);
        color: text-color(reverse);

    }
}